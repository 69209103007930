











import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SvgSearch from '../svg/SvgSearch.vue';

@Component({
  components: { SvgSearch }
})
export default class SearchInput extends Vue {
  @Prop({ type: String, default: () => 'Nach Video und anderen Inhalten suchen' }) placeholder!: string;
  searchString = '';

  get urlSearchString() {
    return (this.$route.query?.suche as string) || '';
  }

  enterSearch() {
    this.$emit('enterSearch', this.searchString);
  }

  mounted() {
    this.searchString = this.urlSearchString;
    if (this.searchString) {
      this.enterSearch();
    }
  }

  @Watch('urlSearchString')
  changeSearchString(value: string) {
    this.searchString = value;
  }
}
