













































import { Component, Vue } from 'vue-property-decorator';
import SvgArrow from '../svg/SvgArrow.vue';
import SvgArrowSmall from '../svg/SvgArrowSmall.vue';
import SvgContact from '../svg/SvgContact.vue';
import SvgLinkTo from '../svg/SvgLinkTo.vue';
import FooterContent from './FooterContent.vue';
import FooterContentInterface from '../../helpers/interface/FooterContent.interface';
import FooterContentStandard from './FooterContentStandard.vue';

@Component({
  components: { FooterContentStandard, FooterContent, SvgLinkTo, SvgArrowSmall, SvgContact, SvgArrow }
})
export default class Footer extends Vue {
  footerNaviBottom = [
    { link: 'https://www.tk.de/techniker/impressum-2013112', label: 'Impressum' },
    {
      link: 'https://www.tk.de/techniker/unternehmensseiten/nutzungs-und-teilnahmebedingungen-2019568',
      label: 'Nutzungs-/Teilnahmebedingungen'
    },
    {
      link: 'https://www.tk.de/techniker/unternehmensseiten/datenschutz-2019572',
      label: 'Datenschutz und Informationsfreiheit'
    },
    { link: 'https://www.tk.de/techniker/unternehmensseiten/barrierefreiheit-2026116', label: 'Barrierefreiheit' }
  ];

  footerContentMedienuniversum: FooterContentInterface = {
    row: [
      [
        {
          title: 'Verantwortlich',
          noTitle: false,
          content: `
              Die Techniker Krankenkasse<br />
              Gesundheitsmanagement &<br />
              Versorgungsmanagement<br />
              Bramfelder Straße 140<br />
              22305 Hamburg
              <a href="https://www.tk.de/techniker" class="d-block text-decoration-underline pt-7">www.tk.de</a>
            `
        },
        {
          title: '',
          noTitle: false,
          content: `Prof. Dr. Roland Rosenstock (Universität Greifswald)<br />
              Prof. Dr. Ines Sura (Universität Greifswald)<br />
              Anja Schweiger (Medienzentrum Greifswald e.V.)<br />
              Birgit Hofmann (Medienzentrum Greifswald e.V.)`
        },
        {
          title: 'Rückfragen richten Sie bitte an:',
          noTitle: false,
          content: `<a href="mailto:medienkompetenz@tk.de" class="text-decoration-underline">medienkompetenz@tk.de</a>`
        }
      ],
      [
        {
          title: 'Unter Mitarbeit von',
          noTitle: false,
          content: `Vilua Healthcare GmbH, KIDS interactive GmbH und<br />
          medizinwelten-services GmbH`
        }
      ]
    ],
    cooperationImages: [
      {
        src: 'https://medienuniversum.info/assets/images/Logo_MZ_freigestellt.png',
        url: 'https://medienzentrum-greifswald.de/'
      },
      {
        src: 'https://medienuniversum.info/assets/images/logo_uni-greifswald.svg',
        url: 'https://www.uni-greifswald.de/',
        class: 'w-65'
      }
    ]
  };
  footerContentLebenMitKrebs: FooterContentInterface = {
    row: [
      [
        {
          title: 'Von',
          noTitle: false,
          content: `TUM - CCC<sup><b style="font-size: small">LMU</b></sup><br>Prof. Dr. med. Martin Halle (TUM)<br>M.Sc. Nicole Erickson (CCC<sup><b style="font-size: small">LMU</b></sup>)<br>Anika Berling (TUM)`
        },
        {
          title: 'Fachliche Freigabe',
          noTitle: false,
          content: `Mitglieder des CCC  München<sup><b style="font-size: small">LMU</b></sup>`
        },
        {
          title: 'Ansprechpartner',
          noTitle: false,
          content: `Dr. phil. Nicole Knaack (TK)<br>M. Sc. Nicole Erickson (CCC<sup><b style="font-size: small">LMU</b></sup>)`
        }
      ]
    ],
    cooperationImages: [
      { src: 'https://lebenmitkrebs.tk.de/assets/images/logo-tum.gif', alt: 'TUM', url: 'https://www.tum.de/' },
      {
        src: 'https://lebenmitkrebs.tk.de/assets/images/CCCM-Logo_LMU.png',
        alt: 'CCC*LMU',
        url: 'http://www.ccc-muenchen.de/'
      },
      {
        src: 'https://lebenmitkrebs.tk.de/assets/images/logo-lmu.png',
        alt: 'LMU',
        url: 'http://www.klinikum.uni-muenchen.de/'
      },
      { src: 'https://lebenmitkrebs.tk.de/assets/images/tk-logo-s.svg', alt: 'Die Techniker', url: 'https://tk.de/' }
    ]
  };

  get footerContent(): FooterContentInterface | null {
    if (this.$route.params?.themeSlug === 'medienuniversum') return this.footerContentMedienuniversum;
    if (this.$route.params?.themeSlug === 'lebenmitkrebs') return this.footerContentLebenMitKrebs;
    return null;
  }
}
