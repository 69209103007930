
















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ViluaAudioDocument from '../../helpers/interface/vilua-audio-document.interface';
import CurrentPodcastStore from '../../store/CurrentPodcastStore';
import SvgHeadphone from '../svg/SvgHeadphone.vue';
import SvgVerticalDots from '../svg/SvgVerticalDots.vue';
import WatchlistSymbols from '../watchlist/WatchlistSymbols.vue';
import PodcastPlayer from './PodcastPlayer.vue';

@Component({
  components: { PodcastPlayer, SvgVerticalDots, WatchlistSymbols, SvgHeadphone }
})
export default class PodcastListItem extends Vue {
  public store: CurrentPodcastStore = getModule(CurrentPodcastStore, this.$store);
  @Prop({ type: Object, required: true }) audioFile!: ViluaAudioDocument;
  @Prop({ type: Array, required: true }) sortedList!: ViluaAudioDocument[];
  @Prop({ type: String, default: () => undefined }) themeSlug!: string;
  @Prop({ type: String, default: () => undefined }) playlistSlug!: string;

  get isActive() {
    return this.store.currentPodcastSlug === this.audioFile._leancms.slug && this.store.isPlaying;
  }

  get playlist() {
    return this.playlistSlug || this.$route.params?.podcastSlug || '';
  }

  get theme() {
    return this.themeSlug || this.$route.params?.themeSlug || '';
  }

  notImplemented() {
    alert('not implemented until yet');
  }
}
