







import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import UserStore from '../../store/UserStore';
import SvgFavorit from '../svg/SvgFavorit.vue';

@Component({
  components: { SvgFavorit }
})
export default class WatchlistSymbols extends Vue {
  public store: UserStore = getModule(UserStore, this.$store);
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: String, required: true }) fileSlug!: string;
  @Prop({ type: String, required: true }) playlistSlug!: string;
  @Prop({ type: String, required: true }) themeSlug!: string;
  @Prop({ type: String, default: () => 'video' }) type!: string;

  toogleMedias() {
    if (this.type === 'video') {
      this.toggleVideo();
    } else {
      this.togglePodcast();
    }
  }

  toggleVideo() {
    if (this.isInWatchlist) {
      this.store.removeWatchlistVideo({ theme: this.themeSlug, playlist: this.playlistSlug, file: this.fileSlug });
    } else {
      this.store.addWatchlistVideo({
        title: this.title,
        theme: this.themeSlug,
        playlist: this.playlistSlug,
        file: this.fileSlug
      });
    }
  }
  togglePodcast() {
    if (this.isInWatchlist) {
      this.store.removeWatchlistPodcast({ theme: this.themeSlug, playlist: this.playlistSlug, file: this.fileSlug });
    } else {
      this.store.addWatchlistPodcast({
        title: this.title,
        theme: this.themeSlug,
        playlist: this.playlistSlug,
        file: this.fileSlug
      });
    }
  }

  get isInWatchlist() {
    return (
      !!this.store.videoPlaylist.find(
        item =>
          item.themeSlug === this.themeSlug &&
          item.playlistSlug === this.playlistSlug &&
          item.fileSlug === this.fileSlug
      ) ||
      !!this.store.audioPlaylist.find(
        item =>
          item.themeSlug === this.themeSlug &&
          item.playlistSlug === this.playlistSlug &&
          item.fileSlug === this.fileSlug
      )
    );
  }
}
