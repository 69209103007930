

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import TkStore from '../../store/TkStore';
import SvgDownload from '../svg/SvgDownload.vue';
import ViluaAttachmentDocument from '../../helpers/interface/vilua-attachment-document.interface';

@Component({
  components: { SvgDownload }
})
export default class AttachmentListItem extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);

  @Prop({ type: Object, required: true }) attachment!: ViluaAttachmentDocument;

  downLoadTimeDsl(size: number): number {
    return Math.round(size / 768);
  }

  downLoadTimeLte(size: number): number {
    return Math.round(size / 50000);
  }

  buildS3Path(path: string): string {
    return this.store.sdk.getFilePath(path);
  }
}
