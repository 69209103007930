import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import CurrentPodcast from '../helpers/interface/CurrentPodcast';
import ViluaAudioDocument from '../helpers/interface/vilua-audio-document.interface';

@Module({ name: 'CurrentPodcastStore' })
export default class CurrentPodcastStore extends VuexModule {
  currentPodcastSlug = '';
  currentPodcastList: ViluaAudioDocument[] = [];
  isPlaying = false;
  podcasts: CurrentPodcast[] = [];
  volume = 100;
  mute = false;

  get currentPodcast() {
    return this.podcasts.find(podcast => podcast.element._leancms.slug === this.currentPodcastSlug);
  }

  @Mutation
  setCurrentPodcast(slug: string) {
    this.currentPodcastSlug = slug;
  }

  @Mutation
  addPodcast(podcast: CurrentPodcast) {
    if (!this.podcasts.find(pod => podcast.element._leancms.slug === pod.element._leancms.slug)) {
      this.podcasts.push(podcast);
    }
  }

  @Mutation
  removePodcast() {
    this.currentPodcastSlug = '';
  }

  @Mutation
  setPlaying(value: boolean) {
    this.isPlaying = value;
  }

  @Mutation
  changeVolume(value: number) {
    this.volume = value;
  }

  @Mutation
  changeDuration(value: number) {
    const currPodcast = this.podcasts.find(podcast => podcast.element._leancms.slug === this.currentPodcastSlug);
    if (currPodcast) {
      currPodcast.duration = value;
    }
  }

  @Mutation
  changeCurrentTime(value: number) {
    const currPodcast = this.podcasts.find(podcast => podcast.element._leancms.slug === this.currentPodcastSlug);
    if (currPodcast) {
      currPodcast.currentTime = value;
    }
  }

  @Mutation
  changeCurrentTimeBySlug(data: { currentTime: number; slug: string }) {
    const podcast = this.podcasts.find(pod => pod.element._leancms.slug === data.slug);
    if (podcast) {
      podcast.currentTime = data.currentTime;
    }
  }

  @Mutation
  setMute(value: boolean) {
    this.mute = value;
  }

  @Mutation
  setCurrentPodcastList(values: ViluaAudioDocument[]) {
    const existingSlugs = this.podcasts.map(pod => pod.element._leancms.slug);
    this.currentPodcastList = values;
    // add not existen Podcast to the list of audioPlaylist for showing currentTime
    this.podcasts = values
      .filter(media => !existingSlugs.includes(media._leancms.slug))
      .reduce((agg, curr) => {
        return [...agg, { element: curr, currentTime: 0, duration: curr.audio.duration || 0 }];
      }, this.podcasts || []);
  }
}
