








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgBurgerMenu extends Vue {
  @Prop({ type: String, default: '25px' }) height!: string;
  @Prop({ type: String, default: '25px' }) width!: string;
}
