











import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import TeaserItem from '../helpers/interface/teaser-item.interface';
import { ImageFileFragment } from '../helpers/interface/fragments.interface';
import TkStore from '../store/TkStore';

@Component
export default class HomeTeaser extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);
  @Prop({ required: true }) teaser!: TeaserItem;
  @Prop({ required: true }) slug!: string;

  get imagePath() {
    const image = this.teaser.image?.['5x1'] ?? this.teaser.image?.['1x1'];
    return this.store.sdk.getFilePath((image?._default as ImageFileFragment)?.path ?? '');
  }

  get imageAlt() {
    return (this.teaser.image?.['5x1'] ?? this.teaser.image?.['1x1'])?.alt;
  }
}
