



















































import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import TkStore from '../store/TkStore';

@Component
export default class PageNotFoundView extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);
  get isBackToLogin() {
    return this.$route.name === 'backToLogin';
  }

  get redirectLink() {
    return this.store.sdk.viluaRedirect;
  }
}
