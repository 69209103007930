




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ErrorFileFragment } from '../helpers/interface/fragments.interface';
import ViluaVideoDocument from '../helpers/interface/vilua-video-document.interface';
import ShowAllLink from './ShowAllLink.vue';
import SvgArrow from './svg/SvgArrow.vue';
import SvgArrowSmall from './svg/SvgArrowSmall.vue';
import SvgPlay from './svg/SvgPlay.vue';
import VideoTile from './VideoTile.vue';

@Component({
  components: { ShowAllLink, SvgArrowSmall, VideoTile, SvgArrow, SvgPlay }
})
export default class VideoCarousel extends Vue {
  @Prop({ type: Array, required: true }) readonly elements!: ViluaVideoDocument[];
  @Prop({ type: String, default: () => null }) readonly title!: string;
  @Prop({ type: String, default: () => null }) readonly description!: string;
  @Prop({ type: String, default: () => null }) readonly linkToAll!: string;
  @Prop({ type: Boolean, default: () => false }) readonly related!: boolean;

  vuetify = this.$vuetify;

  get videos() {
    const usedElements = this.elements.filter(element => {
      if (element?.video?.variations?._default) return element;
      console.error(element);
      console.error('republish theme and playlist and failed video');
    });
    if (this.$vuetify.breakpoint.smAndDown) {
      return usedElements.slice(0, 6);
    }
    return usedElements.slice(0, 11);
  }

  getPoster(element: ViluaVideoDocument) {
    const defaultElement = element.poster?._default;
    if (!!defaultElement && !(defaultElement as ErrorFileFragment).error) return defaultElement;
    return null;
  }

  linkToVideo(element: ViluaVideoDocument) {
    return `${this.linkToAll}/${element._leancms.slug}`;
  }
}
