







































import { sortBy } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ViluaAudioDocument from '../../helpers/interface/vilua-audio-document.interface';
import TkSdk from '../../helpers/tkSdk';
import TkStore from '../../store/TkStore';
import { WatchlistItem } from '../../store/UserStore';
import AutoplayBtn from '../AutoplayBtn.vue';
import PaginationButton from '../PaginationButton.vue';
import Sort from '../Sort.vue';
import PodcastListItem from './PodcastListItem.vue';

@Component({
  components: { AutoplayBtn, PaginationButton, Sort, PodcastListItem }
})
export default class PodcastList extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: Array, required: true }) playlist!: (ViluaAudioDocument | WatchlistItem)[];
  @Prop({ type: Boolean, default: () => true }) showAutoplay!: boolean;
  @Prop({ type: String, default: () => 'Alle Podcasts' }) readonly title!: string;

  sortItems = [
    { label: 'Standard', value: 'standard', svgComponent: '' },
    { label: 'Alphabetisch', value: 'title-asc', svgComponent: 'SvgSortAlpha' },
    { label: 'Neueste zuerst', value: 'createdDate-desc', svgComponent: 'SvgClock' }
  ];

  sortSelect = 'standard';
  autoPlay = true;

  currentPage = 1;

  readonly COUNT_ELEMENTS = 8;

  get isWatchlist() {
    return this.playlist.length && (this.playlist[0] as WatchlistItem).themeSlug;
  }

  get audioFileList() {
    return this.isWatchlist
      ? (this.playlist.map(item => item.audio) as ViluaAudioDocument[])
      : (this.playlist as ViluaAudioDocument[]);
  }

  get countPage() {
    return Math.ceil((this.audioFileList ?? []).length / this.COUNT_ELEMENTS);
  }

  get elements() {
    let maxSlice = this.COUNT_ELEMENTS;
    const startSlice = this.currentPage * maxSlice - maxSlice;
    maxSlice = maxSlice * this.currentPage;
    return this.sortElements.slice(startSlice, maxSlice);
  }

  get sortElements() {
    if (!this.playlist) {
      return [];
    }

    const unsortElements = this.playlist;
    let sortElements = unsortElements;
    switch (this.sortSelect) {
      case 'standard':
        return unsortElements;
      case 'title-asc':
        sortElements = TkSdk.sortElementsByTitle(unsortElements) as (WatchlistItem | ViluaAudioDocument)[];
        break;
      case 'createdDate-desc':
        sortElements = sortBy(unsortElements, (el: ViluaAudioDocument | WatchlistItem) => {
          const audio = this.isWatchlist ? (el as WatchlistItem).audio : (el as ViluaAudioDocument);
          return new Date(audio?._leancms.createdAt || new Date());
        });
        sortElements = sortElements.reverse();
        break;
    }
    return sortElements;
  }

  get sortedAudioFileList() {
    return this.sortElements.map(item => (item as WatchlistItem).audio);
  }

  getViluaAudio(element: ViluaAudioDocument | WatchlistItem) {
    return (element as WatchlistItem).playlistSlug ? (element as WatchlistItem).audio : (element as ViluaAudioDocument);
  }

  notImplementedHint() {
    alert('not implemented yet');
  }
}
