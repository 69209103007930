


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgArrow extends Vue {
  @Prop({ default: () => 30 }) height!: number;
  @Prop({ default: () => 30 }) width!: number;
  @Prop({ default: () => '90' }) rotate!: string;
}
