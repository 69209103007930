








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgPlayBtn extends Vue {
  @Prop({ default: () => '48px' }) height!: string;
  @Prop({ default: () => '48px' }) width!: string;
}
