



import { Component, Vue } from 'vue-property-decorator';
import SvgSearch from '../svg/SvgSearch.vue';
import SearchInput from './SearchInput.vue';

@Component({
  components: { SearchInput, SvgSearch }
})
export default class SearchField extends Vue {
  openSearch(searchString: string) {
    this.$router.push(`/suche?suche=${searchString}`);
    this.$emit('enterSearch');
  }
}
