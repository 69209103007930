















import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { FallbackImage } from '../../enums/FallbackImage';
import { ErrorFileFragment, ImageFileFragment } from '../../helpers/interface/fragments.interface';
import Playlist from '../../helpers/interface/playlist.interface';
import TkStore from '../../store/TkStore';
import SvgArrow from '../svg/SvgArrow.vue';
import PodcastSection from '../../components/podcast/PodcastSection.vue';
import VideoCarousel from '../../components/VideoCarousel.vue';

@Component({
  components: {
    SvgArrow,
    PodcastSection,
    VideoCarousel
  }
})
export default class CardsSmall extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: Object, required: true }) playlist!: Playlist;
  @Prop({ type: Boolean, default: () => false }) readonly related!: boolean;

  imageFailed = false;

  get elements() {
    return [this.playlist].slice(0, 3);
  }

  get themeSlug() {
    return this.$route.params?.themeSlug || 'rauchfrei';
  }

  heroImage(playlist: Playlist) {
    // if the image path is given, but the image is not available
    if (this.imageFailed) {
      return FallbackImage.smallCard;
    }

    const firstElement = (playlist?.videos ?? [null])[0];
    if (!firstElement?.poster?._default) {
      console.error('first Video in playlist Image default NOT EXIST');
      return FallbackImage.smallCard;
    }
    if ((firstElement.poster._default as ErrorFileFragment).error) {
      console.error('first video in playlist Image file error', firstElement.poster._default);
      return FallbackImage.smallCard;
    }
    return this.store.sdk.getFilePath((firstElement.poster._default as ImageFileFragment).path || '');
  }

  onImgError() {
    this.imageFailed = true;
  }
}
