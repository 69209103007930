

















import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AllVideos from '../components/AllVideos.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import AttachmentList from '../components/attachment/AttachmentList.vue';
import HeroItem from '../components/hero/HeroItem.vue';
import RelatedContent from '../components/RelatedContent.vue';
import TallCardCarousel from '../components/TallCardCarousel.vue';
import SlugValidator from '../mixins/SlugValidator';
import TkStore from '../store/TkStore';
@Component({
  components: { TallCardCarousel, AttachmentList, RelatedContent, AllVideos, Breadcrumb, HeroItem }
})
export default class PlaylistView extends SlugValidator {
  public store: TkStore = getModule(TkStore, this.$store);

  get hasContent(): boolean {
    return (!!this.playlist && (!!this.playlist?.videos || !!this.playlist?.recipes)) || !!this.playlist?.attachments;
  }

  /**
   * a playlist can be a
   * - videoPlaylist
   * - attachmentPlaylist
   */
  get playlist() {
    let playlist = this.store.videoPlaylists.find(
      playlist => playlist._leancms.slug === this.$route.params?.playlistSlug
    );

    if (!playlist) {
      playlist = this.store.attachmentPlaylist.find(
        playlist => playlist._leancms.slug === this.$route.params?.playlistSlug
      );
    }

    if (!playlist) {
      playlist = this.store.recipePlaylist.find(
        playlist => playlist._leancms.slug === this.$route.params?.playlistSlug
      );
    }

    return playlist;
  }

  get videos() {
    return this.playlist?.videos ?? this.playlist?.recipes;
  }
}
