import Vue from 'vue';
import Vuex from 'vuex';
import TkStore from './TkStore';
import UserStore from './UserStore';
import CurrentPodcastStore from './CurrentPodcastStore';
import VideoPlayerStore from './VideoPlayerStore';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: { TkStore, UserStore, CurrentPodcastStore, VideoPlayerStore: VideoPlayerStore },
  plugins: [
    new VuexPersistence({
      key: 'UserStore',
      modules: ['UserStore']
    }).plugin,
    new VuexPersistence({
      key: 'CurrentPodcastStoreV2',
      modules: ['CurrentPodcastStore']
    }).plugin
  ]
});
