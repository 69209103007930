

































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import SearchField from '../search/SearchField.vue';
import SearchInput from '../search/SearchInput.vue';
import SvgArrowSmall from '../svg/SvgArrowSmall.vue';
import SvgBurgerMenu from '../svg/SvgBurgerMenu.vue';
import SvgClose from '../svg/SvgClose.vue';
import SvgLogout from '../svg/SvgLogout.vue';
import SvgSearch from '../svg/SvgSearch.vue';
import TkLogo from '../TkLogo.vue';

@Component({
  components: { SearchField, SearchInput, SvgClose, SvgArrowSmall, SvgBurgerMenu, SvgSearch, SvgLogout, TkLogo }
})
export default class NavigationHamburgerMenu extends Vue {
  searchFieldFocused = false;
  searchMenuOpen = false;
  searchString = '';

  isMenuOpen = false;
  navigationElements = [
    {
      mainTitle: 'Aktuelles',
      url: 'https://ecoach.tk.de/de/startseite',
      items: []
    },
    {
      mainTitle: 'Mein Coaching',
      url: 'https://ecoach.tk.de/de/mein-coaching/uebersicht',
      items: [
        {
          title: 'Meine Aufgaben',
          url: 'https://ecoach.tk.de/de/startseite'
        },
        {
          title: 'Aktivitätenübersicht',
          url: 'https://ecoach.tk.de/de/mein-coaching/aktivitaeten'
        },
        {
          title: 'Mein Coaching-Plan',
          url: 'https://ecoach.tk.de/de/mein-coaching/plan'
        },
        {
          title: 'Meine Auswertungen',
          url: 'https://ecoach.tk.de/de/mein-coaching/auswertungen'
        }
      ]
    },
    {
      mainTitle: 'Information',
      url: 'https://ecoach.tk.de/de/information',
      items: [
        {
          title: 'Wissen',
          url: 'https://ecoach.tk.de/de/wissen'
        },
        {
          title: 'Kochbuch',
          url: 'https://ecoach.tk.de/de/kochbuch/uebersicht'
        }
      ]
    },
    {
      mainTitle: 'Meine Daten',
      url: 'https://ecoach.tk.de/de/meine-daten/uebersicht',
      items: [
        {
          title: 'Mein Gesundheitsprofil',
          url: 'https://ecoach.tk.de/de/meine-daten/gesundheitsprofil'
        },
        {
          title: 'Persönliche Daten',
          url: 'https://ecoach.tk.de/de/meine-daten/persoenliche-daten-gast'
        }
      ]
    }
  ];

  get urlSearchString() {
    return (this.$route.query?.suche as string) || '';
  }

  get containerStyle() {
    return this.$vuetify.breakpoint.smAndDown ? { 'min-height': `${this.$vuetify.breakpoint.height}px` } : {};
  }

  openSearch(): void {
    this.$router.push({
      name: 'Search',
      query: { suche: this.searchString }
    });
  }

  navigateToIndex(): void {
    if (this.$route.name !== 'Project') {
      this.$router.push({ name: 'Project' });
    }
  }

  navigateToUrl(url: string): void {
    this.isMenuOpen = false;

    if (url.indexOf('http') === -1 && this.$route.path !== url) {
      this.$router.push(url);
    } else if (url.indexOf('http') > -1) {
      window.location.href = url;
    }
  }

  leaveSearchMenu() {
    this.searchMenuOpen = false;
  }

  leaveMenu() {
    this.isMenuOpen = false;
  }

  get activeNavPanels() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return [];
    }
    const activePanels = [];

    for (let i = 0; i <= 3; i++) {
      activePanels.push(i);
    }

    return activePanels;
  }

  get menuActionsVisible() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get appBarHeight(): number {
    return this.$vuetify.breakpoint.mdAndUp ? 65 : 51;
  }

  mounted() {
    this.searchString = this.urlSearchString;
  }

  @Watch('urlSearchString')
  changeSearchString(value: string) {
    this.searchString = value;
  }
}
