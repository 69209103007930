




































import { Component, Prop, Vue } from 'vue-property-decorator';
import ViluaVideoDocument from '../../helpers/interface/vilua-video-document.interface';
import ShowAllLink from '../ShowAllLink.vue';
import VideoTile from '../VideoTile.vue';
import Playlist from '../../helpers/interface/playlist.interface';
import SvgPlay from '../svg/SvgPlay.vue';
import PodcastSection from '../../components/podcast/PodcastSection.vue';

@Component({
  components: { ShowAllLink, SvgPlay, VideoTile, PodcastSection }
})
export default class CardsBig extends Vue {
  @Prop({ type: Object, required: true }) playlist!: Playlist;
  @Prop({ type: Boolean, default: () => false }) readonly related!: boolean;
  @Prop({ type: String, default: () => null }) readonly linkToAll!: string;

  get videos() {
    return (this.playlist.videos ?? [])?.slice(0, 2) || [];
  }

  get themeSlug() {
    return this.$route.params?.themeSlug || 'rauchfrei';
  }

  linkToVideo(element: ViluaVideoDocument) {
    return `/${this.themeSlug}/${this.playlist._leancms.slug}/${element._leancms.slug}`;
  }
}
