















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgArrowSmall extends Vue {
  @Prop({ default: () => '30px' }) height!: string;
  @Prop({ default: () => '30px' }) width!: string;
  @Prop({ default: () => '90' }) rotate!: string;
}
