









import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import UserStore from '../store/UserStore';
@Component
export default class AutplayBtn extends Vue {
  public user: UserStore = getModule(UserStore, this.$store);
  get autoplay() {
    return this.user.autoplay;
  }

  set autoplay(value) {
    this.user.changeAutoplay(value);
  }
}
