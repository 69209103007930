
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import TkStore from '../store/TkStore';
import PodcastSection from './podcast/PodcastSection.vue';
import VideoCarousel from './VideoCarousel.vue';

@Component({
  components: { PodcastSection, VideoCarousel }
})
export default class RelatedContent extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);

  @Prop({ type: String, required: true }) playlistSlug!: string;

  get themeSlug() {
    return this.$route.params?.themeSlug;
  }

  get videoPlaylist() {
    return this.store.videoPlaylists.find(playlist => playlist._leancms.slug === this.playlistSlug);
  }
  get audioPlaylist() {
    return this.store.audioPlaylists.find(playlist => playlist._leancms.slug === this.playlistSlug);
  }
  get attachmentPlaylist() {
    return this.store.attachmentPlaylist.find(playlist => playlist._leancms.slug === this.playlistSlug);
  }
}
