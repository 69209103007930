import { sortBy } from 'lodash';
import { WatchlistItem } from '../store/UserStore';
import { EnvResponse } from './interface/env-response.interface';
import { ErrorFileFragment } from './interface/fragments.interface';
import SortableByTitle from './interface/sortableByTitle.interface';
import Theme from './interface/theme.interface';
import SearchItemInterface from './interface/SearchItem.interface';
import ViluaRecipeDocument from './interface/vilua-recipe-document.interface';
import ViluaVideoDocument from './interface/vilua-video-document.interface';
import ViluaRecipeResponse from './interface/ViluaRecipeResponse.interface';

export default class TkSdk {
  domain: string | null = null;

  constructor(env: EnvResponse) {
    if (!env) throw new Error('no env defined!');
    this.domain = env.cdn;
    this.apiVilua = env.vilua_api;
    this.viluaContentProxy = env.vilua_content_proxy;
    this.viluaRedirect = env.vilua_redirect;
    this.viluaRecipeContent = env.vilua_recipe_content;
  }

  public apiVilua = '';
  public viluaContentProxy = '';
  public viluaRedirect = '';
  public viluaRecipeContent = '';

  static getDuration(duration: number) {
    const durationNumber = Math.floor(duration);
    const minutes = Math.floor(durationNumber / 60);
    const seconds = durationNumber % 60;
    const secondsText = seconds < 10 ? '0' + seconds : seconds;
    return minutes + ':' + secondsText;
  }
  static sortElementsByTitle(elements: SortableByTitle[]): SortableByTitle[] {
    const titleString = 'titleString';
    const titleNumber = 'titleNumber';
    return sortBy(
      elements.map(el => {
        const newEl = { titleString: '', titleNumber: 0, ...el };
        newEl[titleString] = el.title.replace(/[\d-]/g, '');
        newEl[titleNumber] = parseInt(el.title.replace(/[^\d.]/g, ''));
        return newEl;
      }),
      [titleString, titleNumber]
    ).map(el => {
      delete el[titleString];
      delete el[titleNumber];
      return el;
    }) as SortableByTitle[];
  }

  getAll(accessToken: string): Promise<Theme[]> {
    let option = {};
    if (process.env.NODE_ENV !== 'development' && !!accessToken) {
      option = { ...option, ...{ headers: { Authorization: `Bearer ${accessToken}` } } };
    }
    return fetch(this.viluaContentProxy + 'aggregations/vilua-theme/v2/all/de_DE/index.json', option).then(value =>
      value.json()
    );
  }
  getSearch(accessToken: string): Promise<SearchItemInterface[]> {
    let option = {};
    if (process.env.NODE_ENV !== 'development' && !!accessToken) {
      option = { ...option, ...{ headers: { Authorization: `Bearer ${accessToken}` } } };
    }
    return fetch(
      this.viluaContentProxy + 'aggregations/vilua-theme/v2/search-index/de_DE/index.json',
      option
    ).then(value => value.json());
  }

  getRecipeData(recipeId: string): Promise<ViluaRecipeResponse> {
    return fetch(`${this.viluaRecipeContent}/${recipeId}`).then(value => value.json());
  }

  getFilePath(path: string) {
    return `https://${this.domain}${path}`;
  }

  getThumbnailByVideoDocument(element: ViluaVideoDocument | ViluaRecipeDocument) {
    const thumbnail = element.poster?._thumbnail;
    const defaultElement = element.poster?._default;
    if (!!thumbnail && !(thumbnail as ErrorFileFragment).error) return thumbnail;
    if (!!defaultElement && !(defaultElement as ErrorFileFragment).error) return defaultElement;
    console.error('image not exist', element);
    return null;
  }
}
export function isSame(valueA: WatchlistItem, ValueB: { theme: string; playlist: string; file: string }) {
  return (
    valueA.themeSlug === ValueB.theme && valueA.playlistSlug === ValueB.playlist && valueA.fileSlug === ValueB.file
  );
}
