import Vue from 'vue';
import Vuetify from 'vuetify';
import de from 'vuetify/src/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'lg',
    thresholds: {
      // need one px over breakpoint
      xs: 321,
      sm: 481,
      md: 769,
      lg: 1025
    }
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#494949',
        secondary: '#01A0E2',
        accent: '#242424',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        'anthracite-5': '#f5f5f5',
        'anthracite-50': '#a09f9e',
        'anthracite-80': '#696967'
      }
    }
  },
  lang: {
    locales: { de },
    current: 'de'
  }
});
