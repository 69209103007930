

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgSortAlpha extends Vue {
  @Prop({ default: () => '18px' }) height!: string;
  @Prop({ default: () => '18px' }) width!: string;
}
