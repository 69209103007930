import { Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { Component } from 'vue-property-decorator';
import TkStore from '../store/TkStore';

@Component
export default class SlugValidator extends Vue {
  protected tkStore: TkStore = getModule(TkStore, this.$store);

  async beforeMount() {
    await this.validateSlugs();
  }

  async validateSlugs() {
    //@ts-ignore
    const pathParams = this.$route.params;

    if (pathParams.themeSlug) {
      await this.validateThemeSlug();
    }

    if (pathParams.playlistSlug) {
      await this.validatePlaylistSlug(pathParams.playlistSlug);
    }

    if (pathParams.videoSlug) {
      const playlistSlug = pathParams.playlistSlug || '';
      await this.validateVideoSlug(playlistSlug, pathParams.videoSlug);
    }

    if (pathParams.podcastSlug) {
      await this.validatePodcastSlug(pathParams.podcastSlug);
    }
  }

  get storedTheme() {
    return this.tkStore.themes.find(theme => theme._leancms.slug === this.typedThemeSlug);
  }

  get typedThemeSlug() {
    return this.$route.params?.themeSlug || '';
  }

  validateThemeSlug() {
    if (!this.storedTheme) {
      this.navigateToPageNotFound();
    }
  }

  async validatePlaylistSlug(slug: string) {
    const videoPlaylist = (this.tkStore.videoPlaylists ?? []).find(playlist => playlist._leancms.slug === slug);
    const recipePlaylist = (this.tkStore.recipePlaylist ?? []).find(playlist => playlist._leancms.slug === slug);
    const attachmentPlaylist = (this.tkStore.attachmentPlaylist ?? []).find(
      playlist => playlist._leancms.slug === slug
    );
    if (
      (!videoPlaylist || videoPlaylist.videos?.length === 0) &&
      (!recipePlaylist || recipePlaylist.recipes?.length === 0) &&
      (!attachmentPlaylist || attachmentPlaylist.attachments?.length === 0)
    ) {
      await this.navigateToPageNotFound();
    }
  }

  async validateVideoSlug(playlistSlug: string, videoSlug: string) {
    const video = await this.tkStore.getVideo({
      playlistSlug: playlistSlug,
      videoSlug: videoSlug
    });

    if (!video) {
      await this.navigateToPageNotFound();
    }
  }

  async validatePodcastSlug(slug: string) {
    const audioPlaylist = this.tkStore.audioPlaylists.find(audioPlaylist => audioPlaylist?._leancms?.slug === slug) ?? {
      audios: []
    };

    if (!audioPlaylist.audios || audioPlaylist.audios.length === 0) {
      await this.navigateToPageNotFound();
    }
  }

  navigateToPageNotFound() {
    this.$router.replace({
      name: 'PageNotFound'
    });
  }
}
