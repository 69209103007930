
















import { sortBy } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { ImageFileFragment } from '../../helpers/interface/fragments.interface';
import SearchItemInterface from '../../helpers/interface/SearchItem.interface';
import TkSdk from '../../helpers/tkSdk';
import TkStore from '../../store/TkStore';
import Pagination from '../Pagination.vue';
import Sort from '../Sort.vue';
import SvgPlay from '../svg/SvgPlay.vue';
import VideoTile from '../VideoTile.vue';

@Component({
  components: { Sort, Pagination, VideoTile, SvgPlay }
})
export default class SearchResult extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: Array, required: true }) searchedElements!: SearchItemInterface[];

  sortItems = [
    { label: 'Standard', value: 'standard', svgComponent: '' },
    { label: 'Alphabetisch', value: 'title-asc', svgComponent: 'SvgSortAlpha' },
    { label: 'Neueste zuerst', value: 'createdDate-desc', svgComponent: 'SvgClock' }
  ];

  sortSelect = 'standard';

  get sortElements() {
    const unsortElements = this.searchedElements as SearchItemInterface[];
    let sortElements = unsortElements;
    switch (this.sortSelect) {
      case 'standard':
        return unsortElements;
      case 'title-asc':
        sortElements = TkSdk.sortElementsByTitle(unsortElements) as SearchItemInterface[];
        break;
      case 'createdDate-desc':
        sortElements = sortBy(unsortElements as SearchItemInterface[], (el: SearchItemInterface) => {
          return el?.createdDate;
        });
        sortElements = sortElements.reverse();
        break;
    }
    return sortElements;
  }

  searchItem(element: object): SearchItemInterface {
    return element as SearchItemInterface;
  }

  getImage(element: SearchItemInterface) {
    return {
      path: element?.posterUrl ?? ''
    } as ImageFileFragment;
  }

  getDuration(element: SearchItemInterface) {
    return TkSdk.getDuration(parseInt(element?.duration ?? '') ?? 0);
  }
}
