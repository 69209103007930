
































import { Component, Prop, Vue } from 'vue-property-decorator';
import TeaserItem from '../../helpers/interface/teaser-item.interface';
import SvgArrowSmall from '../svg/SvgArrowSmall.vue';
import HeroItem from './HeroItem.vue';

@Component({
  components: { SvgArrowSmall, HeroItem }
})
export default class Hero extends Vue {
  carouselSlideNumber = 0;
  @Prop({ type: Array, required: true }) readonly teaserItems!: TeaserItem[];
}
