import { render, staticRenderFns } from "./AutoplayBtn.vue?vue&type=template&id=7997a11e&"
import script from "./AutoplayBtn.vue?vue&type=script&lang=ts&"
export * from "./AutoplayBtn.vue?vue&type=script&lang=ts&"
import style0 from "./AutoplayBtn.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCheckbox})
