








































import { VideoJsPlayerOptions } from 'video.js';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AudioFileFragment } from '../../helpers/interface/fragments.interface';
import ViluaAudioDocument from '../../helpers/interface/vilua-audio-document.interface';
import CurrentPodcastStore from '../../store/CurrentPodcastStore';
import TkStore from '../../store/TkStore';
import UserStore from '../../store/UserStore';
import AudioPlayer from '../AudioPlayer.vue';
import SvgClose from '../svg/SvgClose.vue';
import SvgHeadphone from '../svg/SvgHeadphone.vue';
import SvgPauseBtn from '../svg/SvgPauseBtn.vue';
import SvgPlayBtn from '../svg/SvgPlayBtn.vue';

@Component({
  components: { SvgClose, SvgPauseBtn, SvgPlayBtn, AudioPlayer, SvgHeadphone }
})
export default class PodcastFooter extends Vue {
  public store: CurrentPodcastStore = getModule(CurrentPodcastStore, this.$store);
  public userStore: UserStore = getModule(UserStore, this.$store);
  public tkStore: TkStore = getModule(TkStore, this.$store);
  public playerError = false;

  play = false;

  showPlayer = true;

  get headphoneSize(): number {
    return this.$vuetify.breakpoint.smAndDown ? 35 : 105;
  }

  get playerOptions(): VideoJsPlayerOptions {
    const path = (this.store.currentPodcast?.element.audio.variations._default as AudioFileFragment).path || '';
    const audioPath = this.tkStore.sdk.getFilePath(path);
    return {
      autoplay: true,
      controls: true,
      sources: [
        {
          src: audioPath,
          type: 'audio/mp3'
        }
      ]
    } as VideoJsPlayerOptions;
  }

  get isPlaying() {
    return this.store.isPlaying;
  }

  playing() {
    this.play = true;
  }

  close() {
    this.store.removePodcast();
  }

  mounted() {
    if (this.store.isPlaying) {
      this.store.setPlaying(false);
    }
  }

  @Watch('isPlaying')
  async changeIsPlaying(value: boolean) {
    // check is audio-file ending and ist autoplay enable
    if (
      this.userStore.autoplay &&
      !value &&
      this.store.currentPodcast?.currentTime === this.store.currentPodcast?.duration
    ) {
      let nextPodcast: ViluaAudioDocument | null = null;
      // searching for next audioFile
      this.store.currentPodcastList.forEach((podcast, index) => {
        if (podcast._leancms.slug === this.store.currentPodcastSlug) {
          nextPodcast = this.store.currentPodcastList[index + 1];
        }
      });
      // when nextPodcast found then start the next audioFile
      if (nextPodcast) {
        this.showPlayer = false;
        this.play = true;
        this.store.setCurrentPodcast((nextPodcast as ViluaAudioDocument)._leancms.slug || '');
        this.store.changeCurrentTime(0);
        setTimeout(() => {
          this.showPlayer = true;
          this.play = false;
        }, 500);
      }
    }
  }
}
