







import { Component, Prop, Vue } from 'vue-property-decorator';
import TallCardCarousel from '../TallCardCarousel.vue';
import Playlist from '../../helpers/interface/playlist.interface';
@Component({
  components: { TallCardCarousel }
})
export default class CardsTall extends Vue {
  @Prop({ type: Array || Object, required: true }) playlists!: Playlist;
}
