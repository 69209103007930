






















import { Component, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import VideoPlayerStore from '../store/VideoPlayerStore';
import SvgClose from './svg/SvgClose.vue';
import SvgPauseBtn from './svg/SvgPauseBtn.vue';
import SvgPlayBtn from './svg/SvgPlayBtn.vue';
import VideoPlayer from './VideoPlayer.vue';
import WatchlistSymbols from './watchlist/WatchlistSymbols.vue';

@Component({
  components: { VideoPlayer, WatchlistSymbols, SvgClose, SvgPauseBtn, SvgPlayBtn }
})
export default class FloatingVideoPlayer extends Vue {
  public store: VideoPlayerStore = getModule(VideoPlayerStore, this.$store);
  videoIndexKey = 0;

  get model() {
    return this.store.mediaModel;
  }

  get video() {
    return this.store.currentVideo;
  }

  get floatMode(): boolean {
    return this.store.floatMode;
  }

  increaseVideoIndex() {
    this.videoIndexKey++;
  }

  close() {
    if (this.$route.fullPath.includes(this.store.playlistRootPath)) {
      window.scrollTo(0, 0);
    }

    this.store.disableFloatMode();
  }

  @Watch('store.currentVideo', { immediate: true })
  changedCurrentVideo() {
    /**
     * if we ar on the video view page and the floating media player is activated
     * and we select a new video from the videoPlaylist-sidebar,
     * then we need a rerendering of this video component.
     */
    this.increaseVideoIndex();
  }
}
