






















import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
import SvgArrow from './svg/SvgArrow.vue';
import SvgClock from './svg/SvgClock.vue';
import SvgHeadphone from './svg/SvgHeadphone.vue';
import SvgSort from './svg/SvgSort.vue';
import SvgSortAlpha from './svg/SvgSortAlpha.vue';

@Component({
  components: { SvgSort, SvgSortAlpha, SvgArrow, SvgClock, SvgHeadphone }
})
export default class Sort extends Vue {
  @Prop({
    type: Array,
    default: () => [
      { label: 'Alphabetisch', value: 'title-asc', svgComponent: 'SvgSortAlpha' },
      { label: 'Neueste zuerst', value: 'createdDate-desc', svgComponent: 'SvgClock' }
    ]
  })
  items!: { label: string; value: string }[];
  @PropSync('select', { type: String }) syncedSelect!: string;
}
