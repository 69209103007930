



















import { VideoJsPlayerOptions, VideoJsPlayer } from 'video.js';
import videojs from 'video.js';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AudioFileFragment, ErrorFileFragment } from '../helpers/interface/fragments.interface';
import ViluaAudioDocument from '../helpers/interface/vilua-audio-document.interface';
import CurrentPodcastStore from '../store/CurrentPodcastStore';
import TkStore from '../store/TkStore';
import VideoPlayerStore from '../store/VideoPlayerStore';

@Component
export default class AudioPlayer extends Vue {
  public currentPodcastStore: CurrentPodcastStore = getModule(CurrentPodcastStore, this.$store);
  public videoPlayerStore: VideoPlayerStore = getModule(VideoPlayerStore, this.$store);
  public tkStore: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: Object, required: true }) options!: VideoJsPlayerOptions;
  @Prop({ type: Boolean, required: false, default: false }) podcastListItem!: boolean;
  @Prop({ type: Boolean, default: false }) play!: boolean;

  player?: VideoJsPlayer;

  mounted() {
    this.player = videojs(this.$refs.player as Element, this.options);
    this.player.ready(async () => {
      this.player?.currentTime(this.currentPodcastStore.currentPodcast?.currentTime || 0);
    });
  }

  timeupdate() {
    if (this.player?.player_) {
      this.currentPodcastStore.changeCurrentTime(Math.round(this.player?.currentTime() || 0));
    }
  }

  update() {
    setTimeout(() => {
      this.currentPodcastStore.changeCurrentTime(Math.round(this.player?.currentTime() || 0));
      this.currentPodcastStore.changeDuration(this.duration);
    }, 900);
  }

  beforeDestroy() {
    if (this.player) {
      this.player?.dispose();
    }
  }

  get classStyle(): string {
    return this.podcastListItem ? 'podcast-list-item' : 'podcast-player';
  }

  playerOptions() {
    return {
      autoplay: true,
      controls: true,
      controlBar: {
        volumePanel: { inline: true }
      },
      sources: [
        {
          src: this.currentPodcastAudioUrl || null,
          type: 'audio/mp3'
        }
      ]
    } as VideoJsPlayerOptions;
  }

  get currentTimer() {
    if (this.player?.player_) {
      return Math.round(this.player?.currentTime() || 0);
    }
    return 0;
  }

  get duration() {
    if (this.player?.player_) {
      return Math.round(this.player?.duration() || 0);
    }
    return 0;
  }

  get currentPodcastAudioUrl() {
    const podcast = this.currentPodcastStore.currentPodcast?.element?.audio?.variations;
    if (!podcast) {
      console.error('no current podcast found', this.currentPodcastStore.currentPodcast);
      return '';
    }
    if (!podcast?._default || (podcast._default as ErrorFileFragment).error) {
      console.error('no current podcast has no default', podcast);
      return '';
    }
    const basicPath = (podcast._default as AudioFileFragment).path || '';
    return this.tkStore.sdk.getFilePath(basicPath);
  }

  @Emit()
  playing(e: boolean) {
    this.currentPodcastStore.setPlaying(true);
    this.videoPlayerStore.setFloatMode(false);
    this.videoPlayerStore.setVideoPlaying(false);
    return e;
  }

  @Emit()
  paused(e: boolean) {
    this.currentPodcastStore.changeCurrentTime(Math.round(this.player?.currentTime() || 0));
    this.currentPodcastStore.setPlaying(false);
    return e;
  }

  @Watch('store.audioFile')
  async initPlayer(value: ViluaAudioDocument) {
    if (value) {
      this.player = undefined;
      await this.$nextTick();
      this.player = videojs(this.$refs.player as Element, this.playerOptions(), () => {
        if (this.player) {
          this.player.currentTime(this.currentTimer);
        }
      });
    } else {
      this.player = undefined;
    }
  }

  @Watch('play')
  changePlay(value: boolean) {
    if (value) {
      this.player?.pause();
    } else {
      this.player?.play();
    }
  }

  @Watch('store.volume', { immediate: true })
  changeVolume(value: number) {
    const newVolume = this.currentPodcastStore.mute ? 0 : value / 100;
    if (this.player?.player_) this.player?.volume(newVolume);
  }

  @Watch('store.mute', { immediate: true })
  changeMute(value: boolean) {
    const newVolume = value ? 0 : this.currentPodcastStore.volume / 100;
    if (this.player?.player_) this.player?.volume(newVolume);
  }

  @Watch('store.currentPodcast.currentTime', { immediate: true })
  changeCurrentTime(newTime: number, oldTime: number) {
    // nur wenn mehr als eine sekunde gespult wird dann wird die zeit vom player geändert
    if (Math.abs(newTime - oldTime) > 1) {
      if (this.player?.player_) this.player?.currentTime(newTime);
    }
  }
}
