


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { Nutrient } from '../helpers/interface/ViluaRecipeResponse.interface';
import TkStore from '../store/TkStore';
import SvgArrowSmall from './svg/SvgArrowSmall.vue';
@Component({
  components: { SvgArrowSmall }
})
export default class RecipeDescriptionNutrients extends Vue {
  private store: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: Array, required: true }) readonly nutrients!: Nutrient[];
  @Prop({ type: Number, required: true }) readonly defaultPortion!: number;
  private open = false;
  private portion = 0;

  getNutrientPerPerson(value: number) {
    const nutrientPerPerson = (value / this.defaultPortion) * this.portion;
    return Math.round(nutrientPerPerson * 100) / 100;
  }

  @Watch('defaultPortion', { immediate: true })
  handler(value: number) {
    this.portion = value;
  }
}
