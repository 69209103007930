



































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import TkStore from '../../store/TkStore';
import UserStore from '../../store/UserStore';
import SearchField from '../search/SearchField.vue';
import SearchInput from '../search/SearchInput.vue';
import SvgArrowSmall from '../svg/SvgArrowSmall.vue';
import SvgClose from '../svg/SvgClose.vue';
import SvgLogout from '../svg/SvgLogout.vue';
import SvgSearch from '../svg/SvgSearch.vue';
import TkLogo from '../TkLogo.vue';
import NaviagtionHamburgerMenu from './NavigationHamburgerMenu.vue';
import NavigationMedienuniversum from './NavigationMedienuniversum.vue';

@Component({
  components: {
    NavigationMedienuniversum,
    NaviagtionHamburgerMenu,
    SvgClose,
    SearchField,
    SearchInput,
    SvgArrowSmall,
    SvgSearch,
    SvgLogout,
    TkLogo
  }
})
export default class Navigation extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);
  public userStore: UserStore = getModule(UserStore, this.$store);
  private searchFieldFocused = false;
  private searchMenuOpen = false;
  private searchString = '';

  mounted() {
    this.searchString = this.urlSearchString;
  }

  get urlSearchString() {
    return (this.$route.query?.suche as string) || '';
  }

  get appBarHeight(): number {
    return this.$vuetify.breakpoint.mdAndUp ? 65 : 51;
  }

  get isLandingPage(): boolean {
    return this.$route.name === 'Home';
  }

  get themeSlug() {
    return this.$route.params?.themeSlug || '';
  }

  get needLogout(): boolean {
    return !!this.themeConfig;
  }

  get notSpecialTheme(): boolean {
    return this.themeSlug !== 'lebenmitkrebs';
  }

  get themeConfig() {
    return this.store.themeConfig.find(theme => theme.theme === this.themeSlug);
  }

  get navigationComponent(): string {
    if (this.$route.params?.themeSlug === 'medienuniversum') return 'NavigationMedienuniversum';
    return 'NaviagtionHamburgerMenu';
  }

  openSearch(): void {
    if (this.searchString) {
      this.$router.push({
        name: 'Search',
        query: { suche: this.searchString }
      });
    }
  }

  leaveSearchMenu() {
    this.searchMenuOpen = false;
  }

  navigateToUrl(): void {
    const navDirectTo: any = this.store.themes.find(theme => theme._leancms.slug === this.themeSlug) ?? '';
    if (navDirectTo?.directTo) {
      window.location.href = navDirectTo?.directTo;
    } else {
      this.$router.push('/');
    }
  }

  logout() {
    if (this.themeConfig) {
      this.userStore.setUserTokens({ accessToken: '', refreshToken: '', expiresTimestamp: 0 });
      window.location.href = this.themeConfig.url;
    }
  }

  @Watch('urlSearchString')
  changeSearchString(value: string) {
    this.searchString = value;
  }
}
