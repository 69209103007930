


































import { Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Breadcrumb from '../components/Breadcrumb.vue';
import CardsBig from '../components/cards/CardsBig.vue';
import CardsSmall from '../components/cards/CardsSmall.vue';
import CardsTall from '../components/cards/CardsTall.vue';
import hero from '../components/hero/Hero.vue';
import SearchInput from '../components/search/SearchField.vue';
import Teaser from '../components/Teaser.vue';
import { DisplayType } from '../helpers/interface/DisplayType';
import TeaserItem from '../helpers/interface/teaser-item.interface';
import Theme from '../helpers/interface/theme.interface';
import SlugValidator from '../mixins/SlugValidator';
import TkStore from '../store/TkStore';

@Component({
  components: {
    CardsTall,
    Teaser,
    SearchInput,
    Breadcrumb,
    CardsBig,
    CardsSmall,
    hero
  }
})
export default class ThemeView extends SlugValidator {
  public store: TkStore = getModule(TkStore, this.$store);

  private smallCards = DisplayType.smallCards;
  private bigCards = DisplayType.bigCards;
  private tallCards = DisplayType.tallCards;
  private theme: Theme | null = null;

  get themeSlug() {
    return this.$route.params?.themeSlug || this.$store.state.TkStore.themes[0]?._leancms.slug;
  }

  @Watch('themeSlug', { immediate: true })
  changeThemeSlug() {
    this.theme = (this.store.themes.find(theme => theme._leancms.slug === this.themeSlug) as Theme) || null;
  }

  get hero(): TeaserItem[] {
    return this.theme?.hero2 || [];
  }

  get teaser() {
    return this.theme?.teaser;
  }
}
