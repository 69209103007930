



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgFloat extends Vue {
  @Prop({ type: Number, default: 24 }) height!: number;
  @Prop({ type: Number, default: 24 }) width!: number;
}
