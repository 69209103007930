




































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgSearch extends Vue {
  @Prop({ default: () => '35px' }) height!: string;
  @Prop({ default: () => '35px' }) width!: string;
}
