













import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import CurrentPodcastStore from '../../store/CurrentPodcastStore';

@Component
export default class PodcastPlayerVolume extends Vue {
  public store: CurrentPodcastStore = getModule(CurrentPodcastStore, this.$store);

  get volume() {
    if (this.mute) return 0;
    return this.store.volume;
  }

  set volume(value) {
    if (value) this.store.setMute(false);
    this.store.changeVolume(value);
  }

  toggleMute() {
    this.store.setMute(!this.mute);
  }

  get mute() {
    return this.store.volume === 0 || this.store.mute;
  }
}
