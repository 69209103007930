import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import SortableByTitle from '../helpers/interface/sortableByTitle.interface';
import ViluaAudioDocument from '../helpers/interface/vilua-audio-document.interface';
import ViluaVideoDocument from '../helpers/interface/vilua-video-document.interface';
import { isSame } from '../helpers/tkSdk';

export interface WatchlistItem extends SortableByTitle {
  themeSlug: string;
  playlistSlug: string;
  fileSlug: string;
  video?: ViluaVideoDocument;
  audio?: ViluaAudioDocument;
}

@Module({ name: 'UserStore' })
export default class UserStore extends VuexModule {
  autoplay = false;
  videoPlaylist: WatchlistItem[] = [];
  audioPlaylist: WatchlistItem[] = [];

  accessToken?: string = '';
  refreshToken?: string = '';
  expiresTimestamp?: number = 1514149470044;

  @Mutation
  addWatchlistVideo(slugs: { title: string; theme: string; playlist: string; file: string }) {
    this.videoPlaylist = this.videoPlaylist.filter(
      item => !(item.playlistSlug === slugs.playlist && item.fileSlug === slugs.file)
    );
    this.videoPlaylist.push({
      themeSlug: slugs.theme,
      playlistSlug: slugs.playlist,
      fileSlug: slugs.file,
      title: slugs.title
    });
  }
  @Mutation
  removeWatchlistVideo(slugs: { theme: string; playlist: string; file: string }) {
    this.videoPlaylist = this.videoPlaylist.filter(item => !isSame(item, slugs));
  }

  @Mutation
  addWatchlistPodcast(slugs: { title: string; theme: string; playlist: string; file: string }) {
    this.audioPlaylist = this.audioPlaylist.filter(
      item => !(item.playlistSlug === slugs.playlist && item.fileSlug === slugs.file)
    );
    this.audioPlaylist.push({
      themeSlug: slugs.theme,
      playlistSlug: slugs.playlist,
      fileSlug: slugs.file,
      title: slugs.title
    });
  }
  @Mutation
  removeWatchlistPodcast(slugs: { theme: string; playlist: string; file: string }) {
    this.audioPlaylist = this.audioPlaylist.filter(item => !isSame(item, slugs));
  }

  @Mutation
  changeAutoplay(status: boolean) {
    this.autoplay = status;
  }

  @Mutation
  setUserTokens(payload: { accessToken: string; refreshToken: string; expiresTimestamp: number }) {
    this.accessToken = payload.accessToken;
    this.refreshToken = payload.refreshToken;
    this.expiresTimestamp = payload.expiresTimestamp;
  }
}
