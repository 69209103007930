





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { VideoJsPlayerOptions, VideoJsPlayer } from 'video.js';
import videojs from 'video.js';
import { getModule } from 'vuex-module-decorators';
import { ErrorFileFragment, ImageFileFragment } from '../helpers/interface/fragments.interface';
import ViluaRecipeDocument from '../helpers/interface/vilua-recipe-document.interface';
import ViluaVideoDocument from '../helpers/interface/vilua-video-document.interface';
import { FloatingActionButton } from '../interfaces/FloatingActionButton';
import CurrentPodcastStore from '../store/CurrentPodcastStore';
import TkStore from '../store/TkStore';
import VideoPlayerStore from '../store/VideoPlayerStore';
import UserStore from '../store/UserStore';
import { FloatModeActionTypes } from '../types/FloatModeTypes';
import SvgDefloat from './svg/SvgDefloat.vue';
import SvgFloat from './svg/SvgFloat.vue';
import SvgPlayBtn from './svg/SvgPlayBtn.vue';
@Component({
  components: { SvgPlayBtn }
})
export default class VideoPlayer extends Vue {
  public user: UserStore = getModule(UserStore, this.$store);
  public tkStore: TkStore = getModule(TkStore, this.$store);
  public videoPlayerStore: VideoPlayerStore = getModule(VideoPlayerStore, this.$store);
  public currentPodcastStore: CurrentPodcastStore = getModule(CurrentPodcastStore, this.$store);
  @Prop({ type: Object, default: () => null }) options!: VideoJsPlayerOptions;

  playerOptions: VideoJsPlayerOptions = {
    autoplay: false,
    controls: true,
    sources: [
      {
        src: this.videoPath,
        type: 'video/mp4'
      }
    ],
    poster: this.posterImagePath
  } as VideoJsPlayerOptions;
  player!: VideoJsPlayer;

  mounted() {
    if (this.options) {
      this.playerOptions = this.options;
    }

    if (this.videoPlayerStore.currentVideoIndex >= 0 && this.user.autoplay) {
      this.playerOptions.autoplay = true;
    }

    if (this.videoPlayerStore.isPlaying) {
      this.playerOptions.autoplay = true;
    }

    this.player = videojs(this.$refs.videoPlayer as Element, this.playerOptions, () => {
      this.addPlayerHandlerEnded();
    });

    this.player.currentTime(this.videoPlayerStore.currentTime || 0);
  }

  beforeDestroy() {
    if (this.player) {
      this.removePlayerHandlerEnded();
      this.player.dispose();
    }
  }

  get video(): ViluaVideoDocument | ViluaRecipeDocument {
    if (this.videoPlayerStore.currentVideoIndex < 0 && this.videoPlayerStore.currentVideo) {
      return this.videoPlayerStore.currentVideo;
    } else {
      const currIndex = this.videoPlayerStore.currentVideoIndex;
      return this.videoPlayerStore.sortedElements[currIndex];
    }
  }

  get videoPath() {
    const defaultVideo = this.video.video.variations?._default;
    if (!defaultVideo) {
      console.error('default video not exist', this.video.video);
      return '';
    }
    const basicPath = defaultVideo.path;
    return this.tkStore.sdk.getFilePath(basicPath);
  }

  get posterImagePath() {
    const defaultImage = this.video.poster?._default;
    if (!defaultImage || (defaultImage as ErrorFileFragment).error) {
      console.error('poster image path', this.video.poster);
      return '';
    }
    const basicPath = (defaultImage as ImageFileFragment).path;
    return this.tkStore.sdk.getFilePath(basicPath);
  }

  get hasNextVideo(): boolean {
    return this.videoPlayerStore.currentVideoIndex < this.videoPlayerStore.sortedElements.length;
  }

  get duration() {
    return Math.round(this.player?.duration() || 0);
  }

  get autoplay() {
    return this.user.autoplay;
  }

  set autoplay(value) {
    this.user.changeAutoplay(value);
  }

  enableFloatMode(): void {
    this.videoPlayerStore.setTriggeredManually(true);
    this.videoPlayerStore.enableFloatMode();
  }

  disableFloatMode() {
    if (this.video) {
      /**
       * avoid triggering fix player while scrolling top
       * after video was defixed at the video-view
       */
      window.scrollTo(0, 0);

      this.videoPlayerStore.disableFloatMode();
      const originUrl = `${this.videoPlayerStore.playlistRootPath}/${this.video._leancms.slug}`;

      if (this.$route.path !== originUrl) {
        this.$router.push({
          path: originUrl,
          query: {
            'disabled-float-mode': '1'
          }
        });
      }
    }
  }

  inFloatMode(): boolean {
    return this.videoPlayerStore.floatMode;
  }

  changeDuration() {
    this.videoPlayerStore.changeDuration(this.player.duration());
  }

  changeVolume() {
    this.videoPlayerStore.changeVolume(this.player.muted() ? 0 : this.player.volume());
  }

  changePlay() {
    this.currentPodcastStore.removePodcast();
    this.$nextTick().then(() => {
      this.videoPlayerStore.setVideoPlaying(true);
    });
  }

  changePause() {
    this.videoPlayerStore.setVideoPlaying(false);
  }

  updateTime() {
    if (this.player.player_) this.videoPlayerStore.changeCurrentTime(Math.round(this.player?.currentTime() || 0));
  }

  setFloatingControl() {
    if (!this.hasFloatingButton()) {
      if (!this.inFloatMode()) {
        const floatingButton = this.addFloatingButton({
          actionType: FloatModeActionTypes.ENABLE
        });

        if (floatingButton) {
          floatingButton.onclick = () => {
            if (this.video) {
              this.enableFloatMode();
            }
          };
        }
      } else {
        const floatingButton = this.addFloatingButton({
          actionType: FloatModeActionTypes.DISABLE
        });

        if (floatingButton) {
          floatingButton.onclick = () => {
            this.disableFloatMode();
          };
        }
      }
    }
  }

  hasFloatingButton() {
    return !!document.getElementsByClassName('tk-video-player__floating-control')[0];
  }

  addFloatingButton(btnPayload: FloatingActionButton): HTMLElement | null {
    const buttonInstance = btnPayload.actionType === FloatModeActionTypes.ENABLE ? new SvgFloat() : new SvgDefloat();

    const controlBar = document.getElementsByClassName('vjs-control-bar')[0];
    const insertBeforeNode = document.getElementsByClassName('vjs-fullscreen-control')[0];

    buttonInstance.$mount();
    buttonInstance.$el.className = `tk-video-player__floating-control ${btnPayload.actionType}`;
    controlBar.insertBefore(buttonInstance.$el, insertBeforeNode);

    return buttonInstance.$el as HTMLElement;
  }

  addPlayerHandlerEnded() {
    this.player.currentTime(this.videoPlayerStore.currentTime || 0);
    this.player.duration(this.videoPlayerStore.currentDuration || 0);
    this.player.volume(this.videoPlayerStore.volume || 0);

    // handling the edge case video-path is available but not the video-source-file at s3
    this.player.on('error', () => {
      console.error('an error occurs at video player. maybe the source is not available');
      this.$router.replace({ name: 'PageNotFound' });
    });
  }

  async videoEnded() {
    if (this.user.autoplay && this.hasNextVideo) {
      this.videoPlayerStore.setNextVideo();
      this.$emit('next');
    } else {
      this.$emit('ended');
    }
  }

  removePlayerHandlerEnded() {
    if (this.player) {
      this.player.off('ended');
    }
  }

  @Watch('autoplay')
  handlerAutoplay(value: boolean) {
    if (value) {
      this.addPlayerHandlerEnded();
    } else {
      this.removePlayerHandlerEnded();
    }
  }
}
