

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import CurrentPodcast from '../../helpers/interface/CurrentPodcast';
import ViluaAudioDocument from '../../helpers/interface/vilua-audio-document.interface';
import CurrentPodcastStore from '../../store/CurrentPodcastStore';
import TkStore from '../../store/TkStore';
import VideoPlayerStore from '../../store/VideoPlayerStore';
import SvgPauseBtn from '../svg/SvgPauseBtn.vue';
import SvgPlayBtn from '../svg/SvgPlayBtn.vue';
import PodcastPlayerVolume from './PodcastPlayerVolume.vue';

@Component({
  components: {
    PodcastPlayerVolume,
    SvgPauseBtn,
    SvgPlayBtn
  }
})
export default class PodcastPlayer extends Vue {
  public store: CurrentPodcastStore = getModule(CurrentPodcastStore, this.$store);
  public videoPlayerStore: VideoPlayerStore = getModule(VideoPlayerStore, this.$store);
  public tkStore: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: Object, required: true }) audioFile!: ViluaAudioDocument;
  @Prop({ type: Array, required: true }) sortedList!: ViluaAudioDocument[];

  public currTime = 0;

  async clickedPlay() {
    this.videoPlayerStore.setFloatMode(false);
    this.videoPlayerStore.setVideoPlaying(false);
    this.store.addPodcast({
      currentTime: 0,
      duration: 0,
      element: this.audioFile
    } as CurrentPodcast);
    this.store.setCurrentPodcast('');
    await this.$nextTick();
    this.store.setCurrentPodcast(this.audioFile._leancms.slug || '');
    this.store.setCurrentPodcastList(this.sortedList);
  }

  clickedPause() {
    this.store.removePodcast();
    this.currTime = this.currentPodcast?.currentTime || 0;
  }

  get isActive() {
    return this.store.currentPodcastSlug === this.audioFile._leancms.slug && this.store.isPlaying;
  }

  get currentPodcast() {
    const currPod = this.store.podcasts.find(pod => pod.element._leancms.slug === this.audioFile._leancms.slug);
    if (!currPod) {
      return {
        currentTime: 0,
        duration: 0,
        element: this.audioFile
      } as CurrentPodcast;
    }
    return currPod;
  }

  get currentTime() {
    return this.currentPodcast?.currentTime || 0;
  }
  set currentTime(value) {
    this.store.changeCurrentTimeBySlug({ currentTime: value, slug: this.audioFile._leancms.slug || '' });
  }

  get currentTimeFormat() {
    this.currTime = this.currentPodcast?.currentTime || 0;
    return this.transformSecondsToTime(this.currTime);
  }

  get duration() {
    return Math.floor(this.audioFile.audio.duration || 0);
  }

  get durationFormat() {
    return this.transformSecondsToTime(this.duration);
  }

  transformSecondsToTime(time: number) {
    let seconds: string | number = Math.floor(time) % 60;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    let minutes: string | number = Math.floor(time / 60);
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${minutes}:${seconds}`;
  }
}
