















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgClose extends Vue {
  @Prop({ default: () => '25px' }) height!: string;
  @Prop({ default: () => '25px' }) width!: string;
}
