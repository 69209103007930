








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { Ingredient } from '../helpers/interface/ViluaRecipeResponse.interface';
import TkStore from '../store/TkStore';
import EnergySquare from './EnergySquare.vue';
import SvgArrowSmall from './svg/SvgArrowSmall.vue';
@Component({
  components: { EnergySquare, SvgArrowSmall }
})
export default class RecipeDescriptionIngredients extends Vue {
  private store: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: Array, required: true }) readonly ingredients!: Ingredient[];
  @Prop({ type: Number, required: true }) readonly defaultPortion!: number;
  private open = false;
  private portion = 0;

  getFoodStuffUnit(foodstuffUnit: string) {
    const units: { [key: string]: string } = {
      kilogramme: 'kg',
      GRAMME: 'g',
      LITRE: 'l',
      MILLILITRE: 'ml'
    };
    return units[foodstuffUnit] ?? foodstuffUnit;
  }

  getIngredientQuantity(quantity: number) {
    return (quantity / this.defaultPortion) * this.portion;
  }

  @Watch('defaultPortion', { immediate: true })
  handler(value: number) {
    this.portion = value;
  }
}
