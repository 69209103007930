


















import Vue from 'vue';
import { mapState } from 'vuex';
import FloatingVideoPlayer from './components/FloatingVideoPlayer.vue';
import tkFooter from './components/footer/Footer.vue';
import Navigation from './components/navigation/Navigation.vue';
import NavigationFooter from './components/navigation/NavigationFooter.vue';
import PodcastFooter from './components/podcast/PodcastFooter.vue';
import ViluaAccessTokenResponse from './helpers/interface/vilua-access-token-response';
import { ViluaErrorResponse } from './helpers/interface/ViluaErrorResponse';

export default Vue.extend({
  name: 'App',
  data() {
    return {
      userStore: this.$store.state.UserStore
    };
  },
  components: {
    NavigationFooter,
    FloatingVideoPlayer,
    PodcastFooter,
    tkFooter,
    Navigation
  },
  async mounted() {
    if (this.isStoredTokenValid()) {
      await this.refreshUserToken();
    }
    this.$store.commit('initData', { accessToken: this.accessToken });

    const code = this.$route.query?.code ?? '';
    // to remove get param code
    if (code) {
      await this.$router.push(this.$route.path);
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      accessToken: state => state.UserStore.accessToken,
      // @ts-ignore
      refreshToken: state => state.UserStore.refreshToken,
      // @ts-ignore
      expiresTimestamp: state => state.UserStore.expiresTimestamp,
      // @ts-ignore
      sdk: state => state.TkStore.sdk
    })
  },
  methods: {
    async refreshUserToken() {
      // refresh token
      const response: ViluaAccessTokenResponse = await fetch(
        `${this.sdk.apiVilua}/oauth/token?refreshToken=${this.refreshToken}`,
        {
          method: 'POST',
          redirect: 'follow'
        }
      ).then(response => response.json());
      await this.saveResponse(response);
    },
    async saveResponse(response: ViluaAccessTokenResponse | ViluaErrorResponse) {
      if ((response as ViluaAccessTokenResponse)?.accessToken) {
        const viluaResp: ViluaAccessTokenResponse = response as ViluaAccessTokenResponse;
        this.$store.commit('setUserTokens', {
          accessToken: viluaResp.accessToken,
          refreshToken: viluaResp.refreshToken,
          expiresTimestamp: new Date().getTime() + viluaResp.expiresIn * 1000
        });
      } else {
        this.$store.commit('setUserTokens', {
          accessToken: '',
          refreshToken: '',
          expiresTimestamp: 0
        });
      }
    },
    isStoredTokenValid() {
      return !!this.accessToken && !!this.refreshToken && !!(this.expiresTimestamp - new Date().getTime());
    }
  }
});
