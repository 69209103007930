var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.image)?_c('v-container',{staticClass:"pa-0 ma-0 ma-lg-auto tk-heroitem__container"},[_c('div',{staticClass:"tk-heroitem__blur-container"},[_c('div',{staticClass:"tk-heroitem__blur",style:({
          'background-image': ("url(" + (_vm.getImagePath(_vm.image, '2x1')) + "), url(" + _vm.fallbackImage + ")")
        })})]),_c('div',{staticClass:"tk-heroitem__item"},[_c('div',{staticClass:"tk-heroitem__image d-lg-block d-none",style:({
          'background-image': ("url(" + (_vm.getImagePath(_vm.image, '2x1')) + "), url(" + _vm.fallbackImage + ")")
        })}),_c('div',{staticClass:"tk-heroitem__image d-lg-none d-sm-block d-none",style:({
          'background-image': ("url(" + (_vm.getImagePath(_vm.image, '10x7')) + "), url(" + _vm.fallbackImage + ")")
        })}),_c('div',{staticClass:"tk-heroitem__image d-sm-none d-xs-block",style:({
          'background-image': ("url(" + (_vm.getImagePath(_vm.image, '1x1')) + "), url(" + _vm.fallbackImage + ")")
        })}),_c('div',{staticClass:"tk-heroitem__content mr-xl-10"},[_c('h2',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"tk-heroitem__content__description d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),(_vm.link)?_c('v-btn',{staticClass:"pointer tk-heroitem__btn",attrs:{"outlined":"","href":_vm.externalLink,"to":_vm.internalLink,"color":"primary","x-large":""}},[_vm._v(" "+_vm._s(_vm.buttonText || 'Video starten')+" "),_c('v-spacer'),_c('svg-arrow',{attrs:{"width":20,"height":20}})],1):_vm._e()],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }