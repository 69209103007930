
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { FallbackImage } from '../enums/FallbackImage';
import SvgArrow from './svg/SvgArrow.vue';

@Component({
  components: { SvgArrow }
})
export default class TallCard extends Vue {
  @Prop({ type: String }) readonly imgPath!: string;
  @Prop({ type: String }) readonly title!: string;
  @Prop({ type: String }) readonly to!: string;

  imageFailed = false;

  get isExternalLink() {
    return this.to?.toLowerCase().slice(0, 4) === 'http';
  }

  get image() {
    return this.imageFailed || !this.imgPath ? this.fallbackImage : this.imgPath;
  }

  get fallbackImage() {
    return FallbackImage.teaser;
  }

  onImgError() {
    this.imageFailed = true;
  }
}
