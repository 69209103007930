



























import { sortBy } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import SortableByTitle from '../helpers/interface/sortableByTitle.interface';
import ViluaDocument from '../helpers/interface/vilua-document.interface';
import ViluaRecipeDocument from '../helpers/interface/vilua-recipe-document.interface';
import ViluaVideoDocument from '../helpers/interface/vilua-video-document.interface';
import TkSdk from '../helpers/tkSdk';
import TkStore from '../store/TkStore';
import UserStore, { WatchlistItem } from '../store/UserStore';
import Pagination from './Pagination.vue';
import Sort from './Sort.vue';
import SvgFavorit from './svg/SvgFavorit.vue';
import SvgPlay from './svg/SvgPlay.vue';
import VideoTile from './VideoTile.vue';
import WatchlistSymbols from './watchlist/WatchlistSymbols.vue';

@Component({
  components: { WatchlistSymbols, SvgFavorit, Sort, Pagination, VideoTile, SvgPlay }
})
export default class AllVideos extends Vue {
  public store: UserStore = getModule(UserStore, this.$store);
  public tkStore: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: Array, required: true }) readonly elementList!: (
    | ViluaVideoDocument
    | ViluaRecipeDocument
    | WatchlistItem
  )[];
  @Prop({ type: String, default: () => 'Alle Videos' }) readonly title!: string;

  sortItems = [
    { label: 'Standard', value: 'standard', svgComponent: '' },
    { label: 'Alphabetisch', value: 'title-asc', svgComponent: 'SvgSortAlpha' },
    { label: 'Neueste zuerst', value: 'createdDate-desc', svgComponent: 'SvgClock' }
  ];

  sortSelect = 'standard';

  get sortElements() {
    const unsortElements = this.elements as SortableByTitle[];
    let sortElements = unsortElements;
    switch (this.sortSelect) {
      case 'standard':
        return unsortElements;
      case 'title-asc':
        sortElements = TkSdk.sortElementsByTitle(unsortElements);
        break;
      case 'createdDate-desc':
        sortElements = sortBy(unsortElements, el => {
          const video: ViluaDocument = ((el as WatchlistItem).fileSlug
            ? (el as WatchlistItem).video
            : el) as ViluaDocument;
          return new Date(video?._leancms.createdAt || new Date());
        });

        sortElements = sortElements.reverse();
        break;
    }
    return sortElements;
  }

  get elements() {
    return this.elementList.filter(el => {
      if ((el as WatchlistItem).themeSlug) {
        // @ts-ignore
        return (el as WatchlistItem).video._leancms.slug;
      }
      return !!(el as ViluaDocument)._leancms.slug;
    });
  }

  get themeSlug() {
    return this.$route.params?.themeSlug || 'rauchfrei';
  }

  get playlistSlug() {
    return this.$route.params?.playlistSlug || '';
  }

  getViluaVideo(element: ViluaVideoDocument | ViluaRecipeDocument | WatchlistItem) {
    return (element as WatchlistItem).playlistSlug
      ? (element as WatchlistItem).video
      : (element as ViluaVideoDocument | ViluaRecipeDocument);
  }

  getPoster(element: ViluaVideoDocument | ViluaRecipeDocument | WatchlistItem) {
    const video = this.getViluaVideo(element);
    if (video) return this.tkStore.sdk.getThumbnailByVideoDocument(video);
    return null;
  }

  getVideo(element: ViluaVideoDocument | ViluaRecipeDocument | WatchlistItem) {
    return this.getViluaVideo(element)?.video.variations._default;
  }

  linkToVideo(element: ViluaVideoDocument | ViluaRecipeDocument | WatchlistItem) {
    const playlistSlug = (element as WatchlistItem).playlistSlug ?? this.playlistSlug;
    const themeSlug = (element as WatchlistItem).themeSlug ?? this.themeSlug;
    const fileSlug =
      (element as WatchlistItem).fileSlug ?? (element as ViluaVideoDocument | ViluaRecipeDocument)._leancms.slug;
    if (!themeSlug) return `/${playlistSlug}/${fileSlug}`;
    return `/${themeSlug}/${playlistSlug}/${fileSlug}`;
  }

  getFileSlug(element: ViluaVideoDocument | ViluaRecipeDocument | WatchlistItem) {
    return (element as WatchlistItem).fileSlug ?? (element as ViluaVideoDocument | ViluaRecipeDocument)._leancms.slug;
  }

  getPlaylistSlug(element: ViluaVideoDocument | ViluaRecipeDocument | WatchlistItem) {
    return (element as WatchlistItem).playlistSlug ?? this.playlistSlug;
  }

  getThemeSlug(element: ViluaVideoDocument | ViluaRecipeDocument | WatchlistItem) {
    return (element as WatchlistItem).themeSlug ?? this.themeSlug;
  }
}
