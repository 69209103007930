






































import { Component, Prop, Vue } from 'vue-property-decorator';
import Playlist from '../../helpers/interface/playlist.interface';
import ShowAllLink from '../ShowAllLink.vue';
import SvgHeadphone from '../svg/SvgHeadphone.vue';
import PodcastPlayer from './PodcastPlayer.vue';

@Component({
  components: { PodcastPlayer, ShowAllLink, SvgHeadphone }
})
export default class PodcastSection extends Vue {
  @Prop({ type: Object, required: true }) audioPlaylist!: Playlist;
  @Prop({ type: Boolean, default: () => false }) readonly related!: boolean;
  @Prop({ type: String, default: () => '' }) readonly otherTitle!: string;

  get headphoneSize(): number {
    return this.$vuetify.breakpoint.mdAndDown ? 35 : 60;
  }

  get audioUrl() {
    const firstFile = (this.audioPlaylist?.audios ?? [null])[0];
    if (firstFile) {
      return firstFile.audio.variations._default;
    }
    return null;
  }

  get playerOptions() {
    return {
      autoplay: false,
      controls: true,
      sources: [
        {
          src: this.audioUrl,
          type: 'audio/mp3'
        }
      ]
    };
  }

  get themeSlug() {
    return this.$route.params?.themeSlug || 'rauchfrei';
  }

  get audioPlaylistSlug() {
    return this.audioPlaylist._leancms.slug;
  }

  get title() {
    return this.otherTitle || this.firstAudioFile?.title;
  }

  get firstAudioFile() {
    return (this.audioPlaylist.audios ?? [null])[0];
  }
}
