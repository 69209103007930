













import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgArrow from './svg/SvgArrow.vue';
import SvgArrowLeft from './svg/SvgArrowLeft.vue';
import SvgArrowRight from './svg/SvgArrowRight.vue';

@Component({
  components: { SvgArrowLeft, SvgArrowRight, SvgArrow }
})
export default class PaginationButton extends Vue {
  @Prop({ type: Number, required: true }) currentPage!: number;
  @Prop({ type: Number, required: true }) countPage!: number;

  prev() {
    if (this.currentPage > 1) {
      this.$emit('prev', this.currentPage - 1);
    }
  }
  next() {
    if (this.currentPage < this.countPage) {
      this.$emit('prev', this.currentPage + 1);
    }
  }
}
