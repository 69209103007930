














import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { FallbackImage } from '../enums/FallbackImage';
import { ErrorFileFragment, ImageFileFragment } from '../helpers/interface/fragments.interface';
import ViluaVideoDocument from '../helpers/interface/vilua-video-document.interface';
import TkSdk from '../helpers/tkSdk';
import TkStore from '../store/TkStore';
import SvgPlayBtn from './svg/SvgPlayBtn.vue';

@Component({
  components: { SvgPlayBtn }
})
export default class FocusedVideoTile extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: Object, required: true }) element!: ViluaVideoDocument;
  @Prop({ type: Boolean, required: true }) showMeta!: boolean;

  imageFailed = false;

  get duration() {
    const duration = this.element.video.duration ?? this.element.video.variations._default.duration;
    return TkSdk.getDuration(duration);
  }

  get thumbnail() {
    const image = this.store.sdk.getThumbnailByVideoDocument(this.element);
    if (!image || !!(image as ErrorFileFragment).error) {
      console.error('thumbnail not exist', this.element);
      return FallbackImage.video;
    }
    const basicPath = (image as ImageFileFragment).path || '';

    return !this.imageFailed ? this.store.sdk.getFilePath(basicPath) : FallbackImage.video;
  }

  onImgError() {
    this.imageFailed = true;
  }
}
