



























import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { FallbackImage } from '../enums/FallbackImage';
import { ErrorFileFragment, ImageFileFragment } from '../helpers/interface/fragments.interface';
import TeaserItem from '../helpers/interface/teaser-item.interface';
import TkStore from '../store/TkStore';
import SvgArrow from './svg/SvgArrow.vue';
@Component({
  components: { SvgArrow }
})
export default class Teaser extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: Object, required: true }) teaser!: TeaserItem;

  imageFailed = false;

  get isExternalLink() {
    return this.teaser.buttonLink?.toLowerCase().slice(0, 4) === 'http';
  }

  get internalLink() {
    return !this.isExternalLink ? this.teaser.buttonLink : undefined;
  }

  get externalLink() {
    return this.isExternalLink ? this.teaser.buttonLink : undefined;
  }

  get imagePath() {
    // if the image path is given, but the image is not available
    if (this.imageFailed) {
      return FallbackImage.teaser;
    }

    const image = this.teaser.image?.['16x9'];
    if (!image) {
      console.error('Image not found', this.teaser.image);
      return FallbackImage.teaser;
    }
    const defaultImage = image?._default;
    if (!defaultImage) {
      console.error('Image default not found', this.teaser.image);
      return FallbackImage.teaser;
    }
    if ((defaultImage as ErrorFileFragment).error) {
      console.error('teaser image', this.teaser.image?.['16x9']?._default);
      return FallbackImage.teaser;
    }
    return this.store.sdk.getFilePath((defaultImage as ImageFileFragment).path);
  }

  onImgError() {
    this.imageFailed = true;
  }
}
