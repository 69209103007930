import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { EnvResponse } from '../helpers/interface/env-response.interface';
import Playlist from '../helpers/interface/playlist.interface';
import Theme from '../helpers/interface/theme.interface';
import SearchItemInterface from '../helpers/interface/SearchItem.interface';
import TkSdk from '../helpers/tkSdk';

@Module({ name: 'TkStore' })
export default class TkStore extends VuexModule {
  themes: Theme[] = [];
  videoPlaylists: Playlist[] = [];
  audioPlaylists: Playlist[] = [];
  attachmentPlaylist: Playlist[] = [];
  search: SearchItemInterface[] = [];
  sdk = new TkSdk({} as EnvResponse);
  recipePlaylist: Playlist[] = [];
  themeConfig: { theme: string; url: string }[] = [];

  @Action
  getVideo({ playlistSlug = '', videoSlug = '' }) {
    let playlist: Playlist | undefined = this.videoPlaylists.find(el => el._leancms.slug === playlistSlug);
    if (playlist?.videos?.length) {
      return playlist.videos.find(el => el._leancms.slug === videoSlug) || null;
    }

    playlist = this.recipePlaylist.find(el => el._leancms.slug === playlistSlug);
    if (playlist?.recipes?.length) {
      return playlist.recipes.find(el => el._leancms.slug === videoSlug) || null;
    }
    return null;
  }

  @Mutation
  addSdk(sdk: TkSdk) {
    this.sdk = sdk;
  }

  @Mutation
  addThemeConfig(themeConfig: { theme: string; url: string }[]) {
    this.themeConfig = themeConfig;
  }

  @Mutation
  async initData(payload: { accessToken: string }) {
    this.sdk.getSearch(payload.accessToken).then(data => {
      this.search = data;
    });

    this.themes = await this.sdk.getAll(payload.accessToken);
    const allPlaylists = [];
    for (const theme of this.themes) {
      const playlists = [
        ...(theme.featured ?? []),
        ...((theme.playlists ?? []).map(playlist => playlist.playlist) ?? []),
        ...((theme.items ?? []).map(themeItem => [themeItem.playlist, ...(themeItem.playlists ?? [])]) ?? []).flat(),
        theme.podcasts
      ];
      allPlaylists.push(...playlists.filter(playlist => !!playlist));
    }

    for (const playlist of allPlaylists) {
      if (playlist.videos) {
        this.videoPlaylists = this.videoPlaylists.filter(
          oldPlaylist => oldPlaylist._leancms.slug !== playlist._leancms.slug
        );
        this.videoPlaylists.push(playlist);
      }
      if (playlist.audios) {
        this.audioPlaylists = this.audioPlaylists.filter(
          oldPlaylist => oldPlaylist._leancms.slug !== playlist._leancms.slug
        );
        this.audioPlaylists.push(playlist);
      }

      if (playlist.attachments) {
        this.attachmentPlaylist = this.attachmentPlaylist.filter(
          oldPlaylist => oldPlaylist._leancms.slug !== playlist._leancms.slug
        );
        this.attachmentPlaylist.push(playlist);
      }

      if (playlist.recipes) {
        this.recipePlaylist = this.recipePlaylist.filter(
          oldPlaylist => oldPlaylist._leancms.slug !== playlist._leancms.slug
        );
        this.recipePlaylist.push(playlist);
      }
    }
  }
}
