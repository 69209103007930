








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { ErrorFileFragment, ImageFileFragment } from '../helpers/interface/fragments.interface';
import TeaserItem from '../helpers/interface/teaser-item.interface';
import TkStore from '../store/TkStore';
import ShowAllLink from './ShowAllLink.vue';
import SvgArrow from './svg/SvgArrow.vue';
import SvgArrowSmall from './svg/SvgArrowSmall.vue';
import TallCard from './TallCard.vue';

@Component({
  components: { TallCard, ShowAllLink, SvgArrowSmall, SvgArrow }
})
export default class TallCardCarousel extends Vue {
  @Prop({ type: String, required: false }) readonly title!: string;
  @Prop({ type: String, required: false }) readonly description!: string;
  @Prop({ type: Array, required: true }) readonly teasers!: Array<TeaserItem>;

  vuetify = this.$vuetify;
  public store: TkStore = getModule(TkStore, this.$store);

  get themeSlug() {
    return this.$route.params?.themeSlug || 'rauchfrei';
  }

  imagePath(index: number) {
    const image = this.teasers[index].image?.['10x7'];
    if (!image) {
      console.error('Image not found', this.teasers[index].image);
      return '';
    }
    const defaultImage = image?._default;
    if (!defaultImage) {
      console.error('Image default not found', this.teasers[index].image);
      return '';
    }
    if ((defaultImage as ErrorFileFragment).error) {
      console.error('teaser image', this.teasers[index].image?.['10x7']?._default);
      return '';
    }
    return this.store.sdk.getFilePath((defaultImage as ImageFileFragment).path);
  }
}
