
















import { Component, Prop, Vue } from 'vue-property-decorator';
import PaginationButton from './PaginationButton.vue';

@Component({
  components: { PaginationButton }
})
export default class Pagination extends Vue {
  @Prop({ type: Array, required: true }) readonly elements!: [];
  @Prop({ type: String, required: true }) readonly title!: string;

  currentPage = 1;

  readonly COUNT_XL_ELEMENTS = 16;
  readonly COUNT_LG_ELEMENTS = 12;
  readonly COUNT_ELEMENTS = 8;

  get reduceElements() {
    let maxSlice = this.COUNT_ELEMENTS;
    if (this.$vuetify.breakpoint.xl) {
      maxSlice = this.COUNT_XL_ELEMENTS;
    } else if (this.$vuetify.breakpoint.lg) {
      maxSlice = this.COUNT_LG_ELEMENTS;
    }
    const startSlice = this.currentPage * maxSlice - maxSlice;
    maxSlice = maxSlice * this.currentPage;
    return this.elements.slice(startSlice, maxSlice);
  }

  get countPage() {
    let count = this.COUNT_ELEMENTS;
    if (this.$vuetify.breakpoint.xl) {
      count = this.COUNT_XL_ELEMENTS;
    } else if (this.$vuetify.breakpoint.lg) {
      count = this.COUNT_LG_ELEMENTS;
    }
    return Math.ceil(this.elements.length / count) || 1;
  }
}
