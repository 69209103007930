


















import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Breadcrumb from '../components/Breadcrumb.vue';
import PodcastList from '../components/podcast/PodcastList.vue';
import HeroItem from '../components/hero/HeroItem.vue';
import RelatedContent from '../components/RelatedContent.vue';
import SlugValidator from '../mixins/SlugValidator';
import TkStore from '../store/TkStore';

@Component({
  components: {
    RelatedContent,
    Breadcrumb,
    HeroItem,
    PodcastList
  }
})
export default class PodcastView extends SlugValidator {
  public store: TkStore = getModule(TkStore, this.$store);

  get audioPlaylist() {
    return (
      this.store.audioPlaylists.find(
        audioPlaylist => audioPlaylist?._leancms?.slug === this.$route.params?.podcastSlug
      ) ?? { audios: [] }
    );
  }
}
