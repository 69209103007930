

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgHeadphone extends Vue {
  @Prop({ type: Number, default: 40 }) height!: number;
  @Prop({ type: Number, default: 40 }) width!: number;
}
