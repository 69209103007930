













import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { FallbackImage } from '../enums/FallbackImage';
import { ImageFileFragment, VideoFileFragment } from '../helpers/interface/fragments.interface';
import TkSdk from '../helpers/tkSdk';
import TkStore from '../store/TkStore';
import SvgPlay from './svg/SvgPlay.vue';

@Component({
  components: { SvgPlay }
})
export default class VideoTile extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: String, required: true }) link!: string;
  @Prop({ type: Object, required: true }) image!: ImageFileFragment;
  // only the tiles at the search results do not have to provide a file
  @Prop({ type: Object, required: false }) file?: VideoFileFragment;
  // all pages (excluded search results) do not need to prive a duration, this we get from the file
  @Prop({ type: String, required: false }) duration?: string;

  imageFailed = false;

  get videoDuration(): string {
    return this.duration ? this.duration : TkSdk.getDuration(this.file?.duration || 0);
  }

  get imagePath() {
    return !this.imageFailed ? this.store.sdk.getFilePath(this.image.path) : FallbackImage.video;
  }

  onImgError() {
    this.imageFailed = true;
  }
}
