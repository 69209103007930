var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.theme)?_c('div',{staticClass:"theme"},[_c('hero',{attrs:{"teaserItems":_vm.hero}}),_c('search-input',{staticClass:"mt-8"}),_vm._l((_vm.theme.items),function(themePlaylist,index){return _c('div',{key:index},[(
        themePlaylist.displayType === _vm.smallCards &&
          !!themePlaylist.playlist._leancms &&
          themePlaylist.playlist != null
      )?_c('cards-small',{attrs:{"playlist":themePlaylist.playlist}}):_vm._e(),(
        themePlaylist.displayType === _vm.bigCards && !!themePlaylist.playlist._leancms && themePlaylist.playlist != null
      )?_c('cards-big',{attrs:{"playlist":themePlaylist.playlist,"link-to-all":("/" + _vm.themeSlug + "/" + (themePlaylist.playlist._leancms.slug))}}):_vm._e(),(
        themePlaylist.displayType === _vm.tallCards &&
          !!themePlaylist.playlists[0]._leancms &&
          themePlaylist.playlists != null
      )?_c('cards-tall',{attrs:{"playlists":themePlaylist.playlists}}):_vm._e()],1)}),(_vm.teaser)?_c('teaser',{attrs:{"teaser":_vm.teaser}}):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }