














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgLinkTo extends Vue {
  @Prop({ default: () => '11px' }) height!: string;
  @Prop({ default: () => '11px' }) width!: string;
  @Prop({ default: () => '0' }) rotate!: string;
}
