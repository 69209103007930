





















import { Component, Prop, Vue } from 'vue-property-decorator';
import FooterContent from '../../helpers/interface/FooterContent.interface';
@Component
export default class Footer extends Vue {
  @Prop({ type: Object, required: true }) data!: FooterContent;
}
