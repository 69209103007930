import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import ViluaRecipeDocument from '../helpers/interface/vilua-recipe-document.interface';
import ViluaVideoDocument from '../helpers/interface/vilua-video-document.interface';

@Module({ name: 'VideoPlayerStore' })
export default class VideoPlayerStore extends VuexModule {
  // essential video data
  triggeredManually = false;
  currentVideoIndex = -1;
  playlistRootPath = '';
  playlistSlug = '';
  themeSlug = '';
  mediaModel = '';
  currentVideo: ViluaVideoDocument | ViluaRecipeDocument | null = null;
  floatMode = false;
  isPlaying = false;
  sortedElements: (ViluaVideoDocument | ViluaRecipeDocument)[] = [];

  // player options
  currentDuration = 0;
  currentTime = 0;
  volume = 100;
  mute = false;

  @Mutation
  setTriggeredManually(value: boolean) {
    this.triggeredManually = value;
  }

  @Mutation
  setPlaylistRootPath(rootPath: string) {
    this.playlistRootPath = rootPath;
  }

  @Mutation
  setPlaylist(playlistSlug: string) {
    this.playlistSlug = playlistSlug;
  }

  @Mutation
  setTheme(themeSlug: string) {
    this.themeSlug = themeSlug;
  }

  @Mutation
  setMediaModel(model: string) {
    this.mediaModel = model;
  }

  @Mutation
  setCurrentVideo(video: ViluaVideoDocument | ViluaRecipeDocument) {
    this.currentVideo = video;
    /**
     * if a video was selected while using the videoPlaylist-sidebar,
     * we have to change the index to ensure the sequence of playing videos
     * that coming next
     */
    //@ts-ignore
    this.currentVideoIndex = this.sortedElements.indexOf(this.currentVideo);
  }

  @Mutation
  setNextVideo() {
    this.currentVideo = null;
    this.isPlaying = false;
    this.currentDuration = 0;
    this.currentTime = 0;

    if (this.currentVideoIndex < 0) {
      this.currentVideoIndex = 0;
    } else {
      this.currentVideoIndex++;
    }

    this.currentVideo = this.sortedElements[this.currentVideoIndex];
  }

  @Mutation
  setCurrentVideoIndex(value: number) {
    this.currentVideoIndex = value;
  }

  @Mutation
  setSortedElements(values: (ViluaVideoDocument | ViluaRecipeDocument)[]) {
    this.sortedElements = values;
  }

  /**
   * Setting player options
   */

  @Mutation
  setMute(value: boolean) {
    this.mute = value;
  }

  @Mutation
  setVideoPlaying(value: boolean) {
    this.isPlaying = value;
  }

  @Mutation
  changeVolume(value: number) {
    this.volume = value;
  }

  @Mutation
  changeDuration(value: number) {
    this.currentDuration = value;
  }

  @Mutation
  changeCurrentTime(value: number) {
    this.currentTime = value;
  }

  /**
   * mutations that affect floating the player
   */

  @Mutation
  enableFloatMode() {
    this.floatMode = true;
  }

  @Mutation
  disableFloatMode() {
    this.floatMode = false;
  }

  @Mutation
  setFloatMode(value: boolean) {
    this.floatMode = value;
  }
}
