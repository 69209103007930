










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class EnergySquare extends Vue {
  @Prop({ type: Number, required: true }) energy!: number;
}
