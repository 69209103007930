

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgVerticalDots extends Vue {
  @Prop({ default: () => '16px' }) height!: string;
  @Prop({ default: () => '16px' }) width!: string;
}
