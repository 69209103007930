

































import { sortBy } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import TkStore from '../../store/TkStore';
import ViluaAttachmentDocument from '../../helpers/interface/vilua-attachment-document.interface';
import AttachmentListItem from './AttachmentListItem.vue';
import PaginationButton from '../PaginationButton.vue';
import Sort from '../Sort.vue';
import SvgDownload from '../svg/SvgDownload.vue';

@Component({
  components: { AttachmentListItem, Sort, SvgDownload, PaginationButton }
})
export default class AttachmentList extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: Array, required: true }) items!: ViluaAttachmentDocument[];
  @Prop({ type: String, required: true }) title!: string[];

  sortItems = [
    { label: 'Standard', value: 'standard', svgComponent: '' },
    { label: 'Alphabetisch', value: 'title-asc', svgComponent: 'SvgSortAlpha' },
    { label: 'Neueste zuerst', value: 'createdDate-desc', svgComponent: 'SvgClock' }
  ];
  sortSelect = 'standard';

  currentPage = 1;
  readonly COUNT_ELEMENTS = 8;

  get countPage() {
    return this.items ? Math.round(this.items?.length / this.COUNT_ELEMENTS) : 1;
  }

  get elements() {
    let maxSlice = this.COUNT_ELEMENTS;
    const startSlice = this.currentPage * maxSlice - maxSlice;
    maxSlice = maxSlice * this.currentPage;
    return this.sortElements.slice(startSlice, maxSlice);
  }

  get sortElements() {
    if (!this.items) {
      return [];
    }

    const unsortElements = this.items;
    let sortElements = unsortElements;
    switch (this.sortSelect) {
      case 'standard':
        return unsortElements;
      case 'title-asc':
        return unsortElements;
      case 'createdDate-desc':
        sortElements = sortBy(unsortElements, (el: ViluaAttachmentDocument) => {
          return new Date(el._leancms.createdAt || new Date());
        });
        sortElements = sortElements.reverse();
        break;
    }
    return sortElements;
  }
}
