
















import { Component, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Breadcrumb from '../components/Breadcrumb.vue';
import SearchInput from '../components/search/SearchField.vue';
import SearchResult from '../components/search/SearchResult.vue';
import TkStore from '../store/TkStore';

@Component({
  components: { SearchInput, SearchResult, Breadcrumb }
})
export default class SearchView extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);
  search = '';
  timeout = null;

  get searchQuery() {
    return (this.$route.query?.suche as string) || '';
  }

  get title() {
    return `${this.searchedElements.length} Ergebnisse für ${this.search}`;
  }

  get searchedElements() {
    return this.store.search
      .filter(item => item.model === 'video')
      .filter(item => item.searchable.toLocaleLowerCase().indexOf(this.search.toLowerCase()) > -1);
  }

  @Watch('searchQuery', { immediate: true })
  changeSearchQuery(query: string) {
    this.search = query;
  }
}
