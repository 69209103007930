





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { FallbackImage } from '../../enums/FallbackImage';
import ViluaImageDocument from '../../helpers/interface/vilua-image-document.interface';
import TkStore from '../../store/TkStore';
import SvgArrow from '../svg/SvgArrow.vue';

@Component({
  components: { SvgArrow }
})
export default class HeroItem extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);
  @Prop({ type: Object, required: true }) image!: ViluaImageDocument;
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: String, required: true }) description!: string;
  @Prop({ type: String, required: false, default: () => '' }) link!: string;
  @Prop({ type: String, required: false, default: () => '' }) buttonText!: string;

  getImagePath(image: ViluaImageDocument, format: string) {
    //@ts-ignore
    return this.store.sdk.getFilePath(image[format]?._default?.path ?? '');
  }

  get isExternalLink() {
    return this.link?.toLowerCase().slice(0, 4) === 'http';
  }

  get externalLink() {
    return this.isExternalLink ? this.link : undefined;
  }
  get internalLink() {
    return !this.isExternalLink ? this.link : undefined;
  }

  get fallbackImage() {
    return FallbackImage.hero;
  }
}
