





import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class ShowAllLink extends Vue {
  @Prop({ type: String, required: true }) link!: string;
  @Prop({ type: String, default: () => 'Alle anzeigen' }) title!: string;
}
