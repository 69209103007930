






import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import TkStore from '../store/TkStore';

@Component
export default class Breadcrumb extends Vue {
  public store: TkStore = getModule(TkStore, this.$store);

  get breadcrumb() {
    const breadcrumb = [
      {
        text: 'Mediathek',
        disabled: false,
        link: true,
        to: '/',
        exact: true
      }
    ];

    const currentProject = this.theme;
    if (currentProject) {
      breadcrumb.push({
        text: currentProject.title,
        disabled: false,
        link: true,
        to: `/${currentProject._leancms.slug}`,
        exact: true
      });
    }
    const currentPlaylist = this.videoPlaylist || this.attachmentPlaylist || this.recipePlaylist;
    if (currentPlaylist) {
      breadcrumb.push({
        text: `Playlist - ${currentPlaylist.title}`,
        disabled: false,
        link: true,
        to: `/${this.$route.params?.themeSlug}/${currentPlaylist._leancms.slug}`,
        exact: true
      });
      const currentVideo = this.video;
      if (currentVideo) {
        breadcrumb.push({
          text: currentVideo.title,
          disabled: false,
          link: true,
          to: `/${this.$route.params?.themeSlug}/${this.$route.params?.playlistSlug}/${currentVideo._leancms.slug}`,
          exact: true
        });
      }
    }
    const currentPodcasts = this.audioPlaylist;
    if (currentPodcasts) {
      breadcrumb.push({
        text: currentPodcasts.title,
        disabled: false,
        link: true,
        to: `/${this.$route.params?.themeSlug}/audioFile/${currentPodcasts._leancms.slug}`,
        exact: true
      });
    }

    if (this.$route.path.indexOf('/rezepte') > -1) {
      breadcrumb.push({
        text: 'Rezepte',
        disabled: false,
        link: true,
        to: `/rezepte`,
        exact: true
      });
    }

    if (this.$route.path.indexOf('/merkliste') > -1) {
      breadcrumb.push({
        text: 'Meine Merkliste',
        disabled: false,
        link: true,
        to: `/merkliste`,
        exact: true
      });
    }

    if (this.$route.path.indexOf('/suche') > -1) {
      breadcrumb.push({
        text: 'Suche',
        disabled: false,
        link: true,
        to: `/suche`,
        exact: true
      });
    }

    breadcrumb[breadcrumb.length - 1].disabled = true;

    return breadcrumb;
  }

  get theme() {
    return this.store.themes.find(theme => theme._leancms.slug === this.$route.params.themeSlug);
  }

  get videoPlaylist() {
    return this.store.videoPlaylists.find(
      videoPlaylist => videoPlaylist._leancms.slug === this.$route.params.playlistSlug
    );
  }

  get video() {
    return this.videoPlaylist?.videos?.find(video => video._leancms.slug === this.$route.params?.videoSlug);
  }

  get audioPlaylist() {
    return this.store.audioPlaylists?.find(
      audioPlaylist => audioPlaylist._leancms.slug === this.$route.params?.podcastSlug
    );
  }

  get attachmentPlaylist() {
    return this.store.attachmentPlaylist?.find(
      attachmentPlaylist => attachmentPlaylist._leancms.slug === this.$route.params?.playlistSlug
    );
  }

  get recipePlaylist() {
    return this.store.recipePlaylist?.find(
      recipePlaylist => recipePlaylist._leancms.slug === this.$route.params?.playlistSlug
    );
  }
}
