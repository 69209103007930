



























import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import SlugValidator from '../mixins/SlugValidator';
import TkStore from '../store/TkStore';
import HomeTeaser from '../components/HomeTeaser.vue';
@Component({
  components: { HomeTeaser }
})
export default class ThemeView extends SlugValidator {
  public store: TkStore = getModule(TkStore, this.$store);

  get themes() {
    return this.store.themes;
  }

  get themeTeasers() {
    return this.themes.map(theme => {
      return {
        _leancms: theme._leancms,
        teaser: theme.hero2?.[0] ?? theme.hero?.[0]
      };
    });
  }
}
