
































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FooterContentStandard extends Vue {
  footerNavi = [
    {
      label: 'Unternehmen',
      links: [
        {
          label: 'Über Die Technike',
          link: 'https://www.tk.de/techniker/unternehmensseiten/unternehmen/ueber-die-tk-2022988',
          linkToSvg: false
        },
        {
          label: 'Vorstand der TK',
          link: 'https://www.tk.de/techniker/unternehmensseiten/unternehmen/vorstand-der-tk-2019456',
          linkToSvg: false
        },
        {
          label: 'Verwaltungsrat der TK',
          link: 'https://www.tk.de/techniker/unternehmensseiten/unternehmen/verwaltungsrat-der-tk-2022986',
          linkToSvg: false
        },
        {
          label: 'Presse und Politik',
          link: 'https://www.tk.de/geschaeftsbericht',
          linkToSvg: false
        },
        {
          label: 'TK im Bundesland',
          link: 'https://www.tk.de/presse/regional/tk-im-bundesland-2051850',
          linkToSvg: false
        },
        {
          label: 'Geschäftsbericht',
          link: 'https://www.tk.de/geschaeftsbericht',
          linkToSvg: true
        }
      ]
    },
    {
      label: 'Oft genutzte Inhalte',
      links: [
        {
          label: 'Magazin',
          link: 'https://www.tk.de/techniker/magazin-2000006',
          linkToSvg: false
        },
        {
          label: 'Adresse ändern',
          link: 'https://www.tk.de/service/form/2005648/adressaenderung.form',
          linkToSvg: false
        },
        {
          label: 'E-Mail-Adresse verwalten',
          link: 'https://www.tk.de/techniker/meine-tk/e-mail-adresse-verwalten-2076058',
          linkToSvg: false
        },
        {
          label: 'Mitgliedsbescheinigung für Arbeitgeber herunterladen',
          link: 'https://www.tk.de/service/form/2006492/arbeitgeberbescheinigung.form',
          linkToSvg: false
        },
        {
          label: 'Familienversicherung online beantragen',
          link: 'https://www.tk.de/service/form/2006290/familienversicherungerstantrag.form',
          linkToSvg: false
        },
        {
          label: 'Newsletter bestellen',
          link: 'https://www.tk.de/techniker/aktionen/newsletter-2084110',
          linkToSvg: false
        }
      ]
    },
    {
      label: 'International',
      links: [
        {
          label: 'English',
          link: 'https://www.tk.de/en',
          linkToSvg: false
        },
        {
          label: 'Türkçe',
          link: 'https://tr.tk.de/',
          linkToSvg: true
        },
        {
          label: '中文',
          link: 'https://zh.tk.de/',
          linkToSvg: true
        },
        {
          label: 'Polski',
          link: 'https://pl.tk.de/',
          linkToSvg: true
        },
        {
          label: 'Español',
          link: 'https://es.tk.de/',
          linkToSvg: true
        },
        {
          label: 'العربية',
          link: 'https://ar.tk.de/',
          linkToSvg: true
        }
      ]
    },
    {
      label: 'Portale',
      links: [
        {
          label: 'Firmenkunden',
          link: 'https://www.tk.de/firmenkunden',
          linkToSvg: false
        },
        {
          label: 'Lebenswelten',
          link: 'https://www.tk.de/lebenswelten',
          linkToSvg: false
        },
        {
          label: 'Leistungserbringer',
          link: 'https://www.tk.de/leistungserbringer',
          linkToSvg: false
        },
        {
          label: 'Karriere',
          link: 'https://www.tk.de/karriere',
          linkToSvg: false
        },
        {
          label: 'Vertriebspartner',
          link: 'https://www.tk.de/vertriebspartner',
          linkToSvg: false
        },
        {
          label: 'Pointer.de',
          link: 'https://www.pointer.de/',
          linkToSvg: true
        }
      ]
    }
  ];

  footerSocials = [
    { link: 'https://www.tk.de/youtube', icon: 'mdi-youtube' },
    { link: 'http://www.tk.de/twitter', icon: 'mdi-twitter' },
    { link: 'https://www.tk.de/instagram', icon: 'mdi-instagram' },
    { link: 'https://www.tk.de/facebook', icon: 'mdi-facebook' },
    { link: 'https://www.tk.de/pinterest', icon: 'mdi-pinterest' }
  ];
}
